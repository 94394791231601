// General
import NotFound from '@/pages/NotFoundPage'

// Main pages
import Dashboard from '@/pages/Dashboard'

const routes = [
	{
		path: '/',
		redirect: '/dashboard'
	},
	{
		path: '/dashboard',
		name: 'dashboard',
		component: Dashboard,
		props: true,
		meta: {
			title: ['Dashboard'],
			configPanel: true,
			menu: 'specific',
			layout: 'dashboard'
		}
	},
	{
		path: '/asset/amernet/dashboard',
		name: 'specific_dashboard',
		component: Dashboard,
		props: true,
		meta: {
			title: ['Amernet', 'Dashboard'],
			configPanel: true,
			layout: 'dashboard_amernet'
		}
	},
	{
		path: '*',
		name: 'error',
		component: NotFound,
		meta: {
			title: ['Error']
		}
	}
]

export default routes
