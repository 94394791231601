<template>
	<div class="config-panel">
		<div class="config-start">
			<template v-if="$topbar.elements.modelNavigation.enabled">
				<div
					v-for="(option, index) of menu"
					:key="index"
					class="config-item padding clickable"
					:class="{ active: activeModelNav(option) }"
					@click="setModelNav(option)"
				>
					{{ option }}
				</div>
			</template>
		</div>
		<div class="config-end">
			<div class="config-item" v-if="$topbar.elements.datepicker">
				<date-range-picker
					ref="picker"
					:opens="'left'"
					:locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm' }"
					:singleDatePicker="false"
					:timePicker="true"
					:timePickerIncrement="1"
					:timePickerSeconds="true"
					:timePicker24Hour="true"
					:showWeekNumbers="true"
					:showDropdowns="true"
					:autoApply="false"
					:ranges="true"
					v-model="pickerDate"
					@update="updateDateRange"
					@toggle="togglePicker"
					:linkedCalendars="true"
				>
					<div class="ranges col-12 col-md-auto" slot="ranges">
						<ul>
							<li
								v-for="(item, index) in dateRanges"
								:key="index"
								@click="setRange(item)"
							>
								{{ item.name }}
							</li>
						</ul>
					</div>
					<div class="datepicker" slot="input" slot-scope="picker">
						{{ dateRangeText }}
					</div>
				</date-range-picker>
				<font-awesome-icon
					class="help-icon"
					icon="info-circle"
					v-tooltip.bottom="'Select the date range for the data to be displayed in.'"
				/>
			</div>
			<div class="config-item" v-if="$topbar.elements.liveToggle">
				<label class="input-background">
					Live Data
					<toggle-button
						:cssColor="true"
						style="margin-left:10px;top:3px;"
						:value="false"
						:sync="true"
						:labels="false"
						@change="setupUpdateTimer"
						v-model="$topbar.options.live"
					/>
				</label>
				<font-awesome-icon
					class="help-icon"
					icon="info-circle"
					v-tooltip.bottom="
						'Toggle if the data should automatically update every refresh interval.'
					"
				/>
			</div>
			<div class="config-item" v-if="$topbar.elements.refreshInterval">
				<cool-select
					class="input-background"
					:disabled="!$topbar.options.live"
					spellcheck="false"
					style="width:210px"
					v-model="$topbar.options.refreshInterval"
					:items="refreshIntervals"
					item-value="value"
					item-text="text"
					:disable-search="true"
					@select="sendUpdate"
				>
					<template slot="input-start">
						Refresh Interval:
					</template>
					<template slot="input-end">
						<font-awesome-icon icon="chevron-down" />
					</template>
				</cool-select>
			</div>
			<div class="config-item clickable" v-if="$topbar.elements.refreshButton">
				<label
					class="input-container"
					v-bind:class="{ 'refresh-disabled': refresh.disabled }"
					@click="forceRefresh(true)"
				>
					<font-awesome-icon class="icon" icon="sync" v-tooltip.bottom="refreshHelp" />
				</label>
			</div>
		</div>
	</div>
</template>

<script>
import { CoolSelect } from 'vue-cool-select'
import DateRanges from '@/config/DateRanges'
import RefreshIntervals from '@/config/RefreshIntervals'
import moment from 'moment'

export default {
	data() {
		return {
			menu: [
				'Overview',
				'Energy Sankey',
				'Production',
				'Storage',
				'Merit',
				'Consumption',
				'Sustainability'
			],
			dateRanges: DateRanges,
			refreshIntervals: RefreshIntervals,
			pickerDate: {
				startDate: DateRanges[0].start(),
				endDate: DateRanges[0].end()
			},
			refresh: {
				delay: 2000,
				disabled: false,
				timer: undefined
			},
			updateInterval: null,
			pickerToggle: () => {}
		}
	},

	components: {
		CoolSelect
	},

	watch: {
		'$topbar.options.refreshInterval'() {
			this.setupUpdateTimer(true)
		}
	},

	computed: {
		refreshHelp() {
			return this.refresh.disabled ? `Refreshing data...` : `Refreshes all data right away.`
		},

		dateRangeText() {
			if (this.$topbar.options.dateRange.name) {
				return this.$topbar.options.dateRange.name
			}

			return `${this.$topbar.options.dateRange.start().format('D MMM. YYYY, HH:mm')} -
            ${this.$topbar.options.dateRange.end().format('D MMM. YYYY, HH:mm')}`
		}
	},

	methods: {
		updateDateRange(data) {
			this.setRange({
				start: () => moment(data.startDate),
				end: () => moment(data.endDate),
				live: false
			})
		},

		togglePicker(state, pickerToggle) {
			this.pickerToggle = pickerToggle
		},

		setRange(data) {
			this.$topbar.options.live = data.live
			this.$topbar.options.dateRange = data

			this.pickerToggle(false, false)
			this.setupUpdateTimer()
		},

		setupUpdateTimer(skipUpdate) {
			if (this.$topbar.options.live) {
				clearInterval(this.updateInterval)
				this.updateInterval = setInterval(() => {
					this.sendUpdate()
				}, this.$topbar.options.refreshInterval)
			} else {
				clearInterval(this.updateInterval)
				this.updateInterval = null
			}

			// One first/last update
			if (!skipUpdate) this.sendUpdate()
		},

		forceRefresh(userToggled) {
			if (userToggled && this.refresh.disabled) return

			this.refresh.disabled = true

			clearInterval(this.refresh.timer)
			this.refresh.timer = setTimeout(() => {
				this.refresh.disabled = false
			}, this.refresh.delay)

			this.sendUpdate()
		},

		sendUpdate() {
			this.$eventHub.$emit('update')
		},

		activeModelNav(target) {
			return target === this.$topbar.options.modelActiveNavigation
		},

		setModelNav(target) {
			this.$eventHub.$emit('model-nav-update', {
				target: target
			})
			this.$topbar.options.modelActiveNavigation = target
		}
	},

	mounted() {
		this.$nextTick(() => {
			this.setupUpdateTimer(true)
		})

		this.$eventHub.$on('force-refresh', this.forceRefresh)
		this.$eventHub.$on('update-date-range', this.updateDateRange)
	},

	beforeDestroy() {
		clearTimeout(this.refresh.timer)
		clearInterval(this.updateInterval)

		this.$eventHub.$off('force-refresh', this.forceRefresh)
		this.$eventHub.$off('update-date-range', this.updateDateRange)
	}
}
</script>

<style lang="scss">
.daterangepicker.dropdown-menu {
	box-shadow: 0 2px 6px 1px rgba(38, 38, 38, 0.1);
}

.help-icon {
	margin-right: 10px;
	font-size: 16px;
	opacity: 0.6;
	transition: 0.15s;
	color: #fff;

	&:hover {
		opacity: 1;
	}
}

.config-panel {
	display: flex;
	justify-content: space-between;
	height: 40px;

	.config-start {
		display: flex;
		justify-content: flex-start;

		.config-item {
			border-right-width: 1px;
		}
	}

	.config-end {
		display: flex;
		justify-content: flex-end;

		.config-item {
			border-left-width: 1px;
		}
	}

	.config-item {
		border-width: 0;
		border-style: solid;
		height: 40px;
		border-color: #666666;

		&.clickable {
			cursor: pointer;

			&:hover {
				background-color: rgba(255, 255, 255, 0.03);
				cursor: pointer;
			}
		}

		&.padding {
			line-height: 40px;
			text-align: center;
			vertical-align: middle;
			padding: 0 15px;
		}

		&.active {
			border-bottom: 2px solid #ffffff;
		}
	}

	.datepicker {
		width: 300px;
		text-align: center;
		vertical-align: middle;
		line-height: 28px;
	}

	.input-container {
		height: 40px;
		line-height: 40px;
		padding: 0 20px;
		font-size: 14px;
		user-select: none;
		outline: transparent;
	}

	.clickable {
		.input-container {
			cursor: pointer;
			opacity: 0.7;
			transition: 0.15s;
		}

		&:hover .input-container {
			opacity: 1;

			&.refresh-disabled {
				opacity: 0.4;
			}
		}
	}

	.input-container.refresh-disabled {
		opacity: 0.4;

		.icon {
			animation: rotating-icon 1s linear infinite;
		}
	}

	.input-background,
	.form-control {
		background: transparent !important;
		color: #fff;
		cursor: pointer;
		padding: 5px 10px;
		border: 1px solid transparent !important;
		height: 40px;
		border-radius: 10px;
	}

	.IZ-select {
		&.input-background {
			padding: 0;
		}

		.IZ-select__item {
			color: #66615b;
		}

		.IZ-select__input-wrap {
			height: 100%;
			padding: 0;

			.IZ-select__input {
				border: none;
				padding: 0 10px;
				margin: 0;
				height: 100%;
				border-radius: 10px;
				font-size: 14px;
				background-color: transparent;
				color: #fff;
				line-height: 100%;

				&.IZ-select__input--focused {
					box-shadow: none;
					background-color: transparent;
				}

				input {
					font-size: 14px;
					color: #fff !important;
				}

				&.IZ-select__input--disabled,
				&.IZ-select__input--disabled input {
					opacity: 0.5;
				}
			}
		}
	}
}
</style>
