// Main Vue
import Vue from 'vue'

// Vue plugins
import FontAwesomeIcon from './svg-icons'
import VueFuse from 'vue-fuse'
import VTooltip from 'v-tooltip'
import PaperDashboard from '../plugins/paperDashboard'
import VueTables from 'vue-tables-2'
import VueMoment from 'vue-moment'
import ToggleButton from 'vue-js-toggle-button'
import DateRangePicker from 'vue2-daterange-picker'
import VueSelect from 'vue-cool-select'
import VueApexCharts from 'vue-apexcharts'

// Styling for plugins
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

Vue.prototype.$model = Vue.observable({}) // TODO - make this a store
Vue.prototype.$selected = Vue.observable({
	local: false
}) // TODO - make this a store

Vue.prototype.$eventHub = new Vue() // TODO - use store

Vue.use(PaperDashboard)
Vue.use(VueFuse)
Vue.use(VTooltip, {
	defaultDelay: 200
})
Vue.use(VueTables.ClientTable)
Vue.use(VueMoment)
Vue.use(ToggleButton)
Vue.use(VueSelect, {
	theme: 'bootstrap'
})
Vue.use(VTooltip)

Vue.component('apexchart', VueApexCharts)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('date-range-picker', DateRangePicker)

export default Vue
