export default [
	{
		i: 'map',
		component: 'Map',
		icon: 'map',
		title: 'Map',
		x: 0,
		y: 0,
		w: 12,
		h: 12,
		minW: 8,
		minH: 12,
		live: true,
		config: {
			format: { suffix: ' MW' },
			max: 30,
			min: -10,
			series: [{ color: '#D1EDF7', label: 'Actual Power', value: 'power' }]
		}
	},
	{
		i: 'number_of_connections',
		component: 'Pie',
		icon: 'network-wired',
		title: 'Number of Connections',
		x: 12,
		y: 0,
		w: 12,
		h: 6,
		minW: 8,
		minH: 6,
		live: true,
		config: {
			format: { decimals: 0 },
			series: [
				{
					color: '#ffcd00',
					label: 'Small Users',
					value: 'small_users'
				},
				{
					color: '#a2cd3a',
					label: 'Large Users',
					value: 'large_users'
				}
			]
		}
	},
	{
		i: 'heat_production_consumption',
		component: 'BarGraph',
		icon: 'fire',
		title: 'Total Heat Production and Consumption',
		x: 24,
		y: 0,
		w: 12,
		h: 6,
		minW: 8,
		minH: 6,
		live: true,
		config: {
			format: { decimals: 0, suffix: ' GJ' },
			series: [
				{ color: '#a2cd3a', label: 'Production', value: 'production' },
				{ color: '#ee7d22', label: 'Consumption', value: 'consumption' },
				{ color: '#e21e26', label: 'Losses', value: 'losses' }
			]
		}
	},
	{
		i: 'own_assets_vs_procured',
		component: 'Pie',
		icon: 'list',
		title: 'Heat | Own Assets vs. Procured',
		x: 12,
		y: 6,
		w: 12,
		h: 6,
		minW: 8,
		minH: 6,
		live: true,
		config: {
			format: { decimals: 0, suffix: ' GJ' },
			series: [
				{
					color: '#ffcd00',
					label: 'Own Assets',
					value: 'own_assets'
				},
				{
					color: '#a2cd3a',
					label: 'Procured',
					value: 'procured'
				}
			]
		}
	},
	{
		i: 'fuel_mix',
		component: 'Pie',
		icon: 'thermometer-three-quarters',
		title: 'Fuel Mix',
		x: 24,
		y: 6,
		w: 12,
		h: 6,
		minW: 8,
		minH: 6,
		live: true,
		config: {
			format: { decimals: 0, suffix: '%' },
			series: [
				{ color: '#FFCD00', label: 'Natural Gas', value: 'natural_gas' },
				{ color: '#A2CD3A', label: 'Biomass', value: 'bio_mass' },
				{ color: '#2A9B9B', label: 'Bio Oil', value: 'bio_oil' },
				{ color: '#277AA9', label: 'Residual Heat: Coal', value: 'residual_heat_coal' },
				{ color: '#314388', label: 'Bio Gas', value: 'bio_gas' },
				{
					color: '#7A3D87',
					label: 'Residual Heat: Industry',
					value: 'residual_heat_industry'
				},
				{ color: '#A36427', label: 'Geothermal', value: 'geothermal' }
			]
		}
	},
	{
		i: 'location',
		component: 'SingleDisplay',
		icon: 'map-marker-alt',
		title: 'Location',
		x: 0,
		y: 12,
		w: 12,
		h: 3,
		minW: 8,
		minH: 3,
		live: true,
		config: {
			format: { decimals: 1, percentage: false },
			options: { color: '#ffcd00', customIcon: false, icon: 'map-marker-alt' }
		}
	},
	{
		i: 'electricity_produced',
		component: 'SingleDisplay',
		icon: 'bolt',
		title: 'Electricity Produced',
		x: 12,
		y: 12,
		w: 12,
		h: 3,
		minW: 8,
		minH: 3,
		live: true,
		config: {
			format: { decimals: 3, percentage: false, suffix: ' MWh' },
			options: { color: '#277AA9', customIcon: false, icon: 'bolt' }
		}
	},
	{
		i: 'co2_emissions',
		component: 'SingleDisplay',
		icon: 'industry',
		title: 'CO2 Emissions per GJ',
		x: 24,
		y: 12,
		w: 12,
		h: 3,
		minW: 8,
		minH: 3,
		live: true,
		config: {
			format: { decimals: 1, percentage: false, suffix: ' kgCO2/GJth' },
			options: { color: '#e21e26', customIcon: false, icon: 'industry' }
		}
	},
	{
		i: 'renewable_share',
		component: 'SingleDisplay',
		icon: 'seedling',
		title: 'Renewable Share',
		x: 0,
		y: 15,
		w: 12,
		h: 3,
		minW: 8,
		minH: 3,
		live: true,
		config: {
			format: { decimals: 1, percentage: false, suffix: '%' },
			options: { color: '#A5CD39', customIcon: false, icon: 'seedling' }
		}
	},
	{
		i: 'primary_fossil_energy',
		component: 'SingleDisplay',
		icon: 'power-off',
		title: 'Primary Fossil Energy Factor',
		x: 12,
		y: 15,
		w: 12,
		h: 3,
		minW: 8,
		minH: 3,
		live: true,
		config: {
			format: { decimals: 1, percentage: false, suffix: ' GJp/GJth' },
			options: { color: '#314388', customIcon: false, icon: 'tachometer-alt' }
		}
	},
	{
		i: 'eor',
		component: 'SingleDisplay',
		icon: 'equals',
		title: 'EOR',
		x: 24,
		y: 15,
		w: 12,
		h: 3,
		minW: 8,
		minH: 3,
		live: true,
		config: {
			format: { decimals: 1, percentage: false, suffix: '%' },
			options: { color: '#7A3D87', customIcon: false, icon: 'tachometer-alt' }
		}
	},
	{
		i: 'revenues_generated',
		component: 'Pie',
		icon: 'file-invoice',
		title: 'Revenues Generated',
		x: 0,
		y: 18,
		w: 12,
		h: 6,
		minW: 8,
		minH: 6,
		live: true,
		config: {
			format: { prefix: '€ ', decimals: 0 },
			max: 25,
			min: -1,
			series: [
				{ color: '#ee7d22', label: 'Heat', value: 'heat' },
				{ color: '#277aa9', label: 'Electricity', value: 'electricity' }
			]
		}
	},
	{
		i: 'costs_breakdown',
		component: 'BarGraph',
		icon: 'euro-sign',
		title: 'Costs Breakdown',
		x: 12,
		y: 18,
		w: 12,
		h: 6,
		minW: 8,
		minH: 6,
		live: true,
		config: {
			format: { decimals: 0, prefix: '€ ' },
			series: [
				{ color: '#ffcd00', label: 'Budgetted', value: 'budgetted' },
				{ color: '#a2cd3a', label: 'Actual', value: 'actual' }
			]
		}
	},
	{
		i: 'carbon_emissions_fuel_type',
		component: 'Pie',
		icon: 'industry',
		title: 'CO2 Emmissions by Fuel Type',
		x: 24,
		y: 18,
		w: 12,
		h: 6,
		minW: 8,
		minH: 6,
		live: true,
		config: {
			format: { decimals: 0, suffix: ' Ton CO2' },
			series: [
				{ color: '#FFCD00', label: 'Natural Gas', value: 'natural_gas' },
				{ color: '#A2CD3A', label: 'Biomass', value: 'bio_mass' },
				{ color: '#2A9B9B', label: 'Bio Oil', value: 'bio_oil' },
				{ color: '#277AA9', label: 'Residual Heat: Coal', value: 'residual_heat_coal' },
				{ color: '#314388', label: 'Bio Gas', value: 'bio_gas' },
				{
					color: '#7A3D87',
					label: 'Residual Heat: Industry',
					value: 'residual_heat_industry'
				},
				{ color: '#A36427', label: 'Geothermal', value: 'geothermal' }
			]
		}
	}
]
