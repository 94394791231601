<template>
	<div class="full">
		<apexchart
			ref="chart"
			v-show="ready"
			width="100%"
			height="100%"
			type="donut"
			:options="options"
			:series="series"
		/>
	</div>
</template>

<script>
import LiveComponent from '@/components/Data/LiveComponent'

export default {
	extends: LiveComponent,
	name: 'Pie',
	data() {
		return {
			type: 'pie',
			series: [],
			total: 0,
			format: Object.assign(
				{
					prefix: '',
					suffix: '',
					decimals: 2,
					round: false
				},
				this.config.format || {}
			)
		}
	},

	mounted() {
		this.$refs.chart.updateOptions({ colors: this.colors })
	},

	computed: {
		colors() {
			return this.config.series.map(e => e.color)
		}
	},

	methods: {
		getOptions() {
			return {
				legend: {
					enabled: true,
					width: 175
				},
				stroke: {
					colors: '#383838',
					width: 2,
					dashArray: 0
				},
				dataLabels: {
					enabled: false
				},
				plotOptions: {
					pie: {
						customScale: 0.82,
						donut: {
							size: '0%',
							labels: {
								show: false,
								name: {
									show: false
								},
								value: {
									show: false
								},
								total: {
									show: false,
									showAlways: false
								}
							}
						}
					}
				}
			}
		},

		updateData(result) {
			if (result === undefined) return
			const series = []
			let total = 0
			for (const index in this.config.series) {
				let value
				if (result[this.config.series[index].value] !== undefined) {
					value = result[this.config.series[index].value]
				} else {
					value = this.series[index] || 0
				}
				total += value
				series.push(value)
			}

			this.total = total
			this.$refs.chart.updateSeries(series, false, false)
		}
	}
}
</script>

<style lang="scss">
body .full .apexcharts-canvas.apexcharts-theme-dark .apexcharts-legend-series {
	margin: 7px 5px 0 5px !important;
}
</style>
