export default [
	{
		text: '1s',
		value: 1000
	},
	{
		text: '5s',
		value: 5000
	},
	{
		text: '10s',
		value: 10000
	},
	{
		text: '15s',
		value: 15000
	},
	{
		text: '30s',
		value: 30000
	},
	{
		text: '1m',
		value: 60000
	},
	{
		text: '2m',
		value: 120000
	},
	{
		text: '5m',
		value: 300000
	},
	{
		text: '10m',
		value: 600000
	},
	{
		text: '15m',
		value: 900000
	},
	{
		text: '30m',
		value: 1800000
	},
	{
		text: '1h',
		value: 3600000
	}
]
