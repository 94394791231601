<template>
	<div class="nav-container">
		<nav class="navbar navbar-expand-lg navbar-light">
			<div class="container-fluid">
				<span class="navbar-title">
					<template v-for="(text, index) of title">
						<span v-if="title.length - 1 > index" :key="index" class="menu-prefix">
							{{ text }}
							<font-awesome-icon class="icon" icon="chevron-right" />
						</span>
						<span :key="index" v-else>
							{{ text }}
						</span>
					</template>
				</span>
				<button
					class="navbar-toggler navbar-burger"
					type="button"
					@click="toggleSidebar"
					:aria-expanded="$sidebar.showSidebar"
					aria-label="Toggle navigation"
				>
					<span class="navbar-toggler-bar" />
					<span class="navbar-toggler-bar" />
					<span class="navbar-toggler-bar" />
				</button>
				<div class="collapse navbar-collapse">
					<ul class="navbar-nav ml-auto">
						<li class="nav-item">
							<a href="#" class="nav-link">
								<font-awesome-icon class="menu-icon" icon="bell" />
								<p>0 Notifications</p>
							</a>
						</li>
						<li class="nav-item">
							<a
								:href="'/oauth/logout?redirect=/?v=' + new Date().getTime()"
								class="nav-link"
							>
								<font-awesome-icon class="menu-icon" icon="sign-out-alt" />
								<p>Log Out</p>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</nav>

		<div class="bottom-nav">
			<config-panel v-if="showConfigPanel" />
		</div>
	</div>
</template>

<style lang="scss">
.nav-container {
	position: fixed;
	z-index: 5;
	transition: 0s !important;
	width: calc(100% - 260px);
}
.bottom-nav {
	background: #383838;
	height: 40px;
	box-shadow: 0 0 10px 0 rgba(38, 38, 38, 0.2);
	width: 100%;
	border-bottom: 1px solid rgba(102, 102, 102, 0.4);
}

.menu-icon {
	font-size: 18px;
	margin-right: 5px;
}
</style>

<script>
import ConfigPanel from './ConfigPanel'

export default {
	components: {
		ConfigPanel
	},
	data() {
		return {
			activeNotifications: false
		}
	},
	computed: {
		title() {
			return this.$route.meta.title || ['Unknown']
		},
		showConfigPanel() {
			return this.$route.meta.configPanel || false
		}
	},
	methods: {
		capitalizeFirstLetter(string) {
			if (string && string.length > 0) {
				return string.charAt(0).toUpperCase() + string.slice(1)
			} else {
				return '-'
			}
		},
		closeDropDown() {
			this.activeNotifications = false
		},
		toggleSidebar() {
			this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
		},
		hideSidebar() {
			this.$sidebar.displaySidebar(false)
		}
	}
}
</script>
