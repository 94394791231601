import SideBar from '@/components/SidebarPlugin'
import TopBar from '@/components/TopbarPlugin'
import GlobalComponents from './globalComponents'
import GlobalDirectives from './globalDirectives'
import 'es6-promise/auto'

// CSS assets
import 'bootstrap/dist/css/bootstrap.css'
import '@/assets/sass/paper-dashboard.scss'

export default {
	install(Vue) {
		Vue.use(GlobalComponents)
		Vue.use(GlobalDirectives)
		Vue.use(SideBar)
		Vue.use(TopBar)
	}
}
