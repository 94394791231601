<template>
	<div class="full">
		<center-display :multiple-blocks="true">
			<div v-for="item in config.series" :key="item.value" class="circle-wrapper">
				<div class="circle-outer">
					<div class="circle-inner">
						<div
							class="circle-inner-line"
							v-bind:style="{ 'border-color': item.color }"
						>
							<center-display>
								<div class="value">{{ getValue(item.value, item.decimals) }}</div>
								<div v-if="item.unit !== undefined" class="unit">
									{{ item.unit }}
								</div>
							</center-display>
						</div>
					</div>
				</div>
				<div v-if="item.name" class="circle-title">{{ item.name }}</div>
			</div>
		</center-display>
	</div>
</template>

<script>
import CenterDisplay from '@/components/Misc/CenterDisplay'
import Backend from '@/services/Backend'
import LiveComponent from '@/components/Data/LiveComponent'

export default {
	extends: LiveComponent,
	name: 'CircleDisplay',
	components: {
		CenterDisplay
	},
	data() {
		return {
			data: {}
		}
	},
	methods: {
		getValue(value, decimals) {
			if (decimals !== undefined) {
				decimals = 2
			}

			if (this.data[value] !== undefined) {
				return Number(this.data[value].toFixed(decimals))
			} else {
				return 0
			}
		},

		updateData(results) {
			this.data = results.reduce((a, b) => {
				for (let i = 1; i < b.columns.length; ++i) {
					a[b.columns[i]] = b.values[0][i]
				}

				return a
			}, {})
		}
	}
}
</script>
