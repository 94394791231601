<template>
	<div class="full">
		<center-display
			:enabled="options.center.enabled"
			:vertical="options.center.vertical"
			:horizontal="options.center.horizontal"
		>
			<table
				v-if="options.header === 'rows'"
				class="table table-striped table-bordered table-condensed"
				v-bind:class="{ 'table-minimal': options.minimal }"
			>
				<thead>
					<tr>
						<th v-for="(configItem, configIndex) in config.series" :key="configIndex">
							{{ configItem.label }}
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item, index) in data" :key="index">
						<td v-for="(configItem, configIndex) in config.series" :key="configIndex">
							<div
								class="table-background"
								v-bind:style="getBackground(configItem, item[configItem.value])"
							></div>
							{{ getFormat(configItem, item[configItem.value]) }}
						</td>
					</tr>
				</tbody>
			</table>
			<table
				v-else-if="options.header === 'alternate'"
				class="table table-striped table-bordered table-condensed"
				v-bind:class="{ 'table-minimal': options.minimal }"
			>
				<thead v-for="(configItem, configIndex) in config.series" :key="configIndex">
					<tr>
						<th :style="{ 'padding-bottom': 0 }">{{ configItem.label }}</th>
					</tr>
					<tr>
						<td
							v-for="(item, index) in data"
							:key="index"
							:style="{ 'padding-top': 0, color: 'rgba(255,255,255,0.7)' }"
						>
							<div
								class="table-background"
								v-bind:style="getBackground(configItem, item[configItem.value])"
							></div>
							{{ getFormat(configItem, item[configItem.value]) }}
						</td>
					</tr>
				</thead>
			</table>
			<table
				v-else
				class="table table-striped table-bordered table-condensed"
				v-bind:class="{ 'table-minimal': options.minimal }"
			>
				<thead>
					<tr v-for="(configItem, configIndex) in config.series" :key="configIndex">
						<th>{{ configItem.label }}</th>
						<td v-for="(item, index) in data" :key="index">
							<div
								class="table-background"
								v-bind:style="getBackground(configItem, item[configItem.value])"
							></div>
							{{ getFormat(configItem, item[configItem.value]) }}
						</td>
					</tr>
				</thead>
			</table>
		</center-display>
	</div>
</template>

<script>
import CenterDisplay from '@/components/Misc/CenterDisplay'
import LiveComponent from '@/components/Data/LiveComponent'

const TYPE_MATCHERS = {
	match: (a, b) => a == b,
	morethan: (a, b) => a > b,
	lessthan: (a, b) => a < b
}

export default {
	extends: LiveComponent,
	name: 'CustomTable',
	components: {
		CenterDisplay
	},

	data() {
		return {
			data: this.config.initData || [] // TODO - not this (set to [{}] now for static components)
		}
	},

	methods: {
		getOptions() {
			return {
				initData: [],
				header: 'rows',
				center: {
					enabled: false
				},
				sort: {
					enabled: false,
					value: 'index',
					stripNoneNumerical: false
				},
				mapIndex: {
					value: 'index',
					startAt: 0
				},
				minimal: false
			}
		},

		getBackground(input, value) {
			if (input.background === undefined) {
				return
			}

			let defaultBackground = {}
			let background = null
			for (let i = 0; i < input.background.length; ++i) {
				let condition = input.background[i]
				if (!('type' in condition)) {
					defaultBackground = { background: condition.color }
				} else {
					if (TYPE_MATCHERS[condition.type](value, condition.target)) {
						background = { background: condition.color }
						break
					}
				}
			}

			return background || defaultBackground
		},

		getFormat(input, value) {
			if (input.static) {
				return input.value
			}

			if (value === undefined || value === null) {
				return 'N/A'
			}

			let data = value

			if (input.format === undefined) {
				input.format = {}
			}

			if (!isNaN(value)) {
				data = this.getValue(value, input.format.decimals)
			}

			let prefix = input.format.prefix !== undefined ? input.format.prefix : ''
			let suffix = input.format.suffix !== undefined ? input.format.suffix : ''

			return prefix + data + suffix
		},

		getValue(value, decimals) {
			return isNaN(value)
				? 0
				: Number(Number(value).toFixed(decimals === undefined ? 2 : decimals))
		},

		getMappedValue(col, val) {
			return col in this.seriesConfig && 'map' in this.seriesConfig[col]
				? this.seriesConfig[col].map[val]
				: val
		},

		updateData(results) {
			this.data = results.map((e, i) =>
				Object.assign(
					...e.columns.map((col, idx) => ({
						[col]: this.getMappedValue(col, e.values[0][idx])
					})),
					{
						[this.options.mapIndex.value]: this.getMappedValue(
							this.options.mapIndex.value,
							i + this.options.mapIndex.startAt
						)
					}
				)
			)
		}
	}
}
</script>
