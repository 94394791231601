<template>
	<div
		v-bind:class="{ 'display-container': enabled }"
		v-bind:style="{ 'justify-content': horizontal, 'align-items': vertical }"
	>
		<div v-if="!multipleBlocks" class="display">
			<slot />
		</div>
		<slot v-else />
	</div>
</template>

<style lang="scss">
.display-container {
	position: absolute;
	display: flex;
	width: 100%;
	height: 100%;
	z-index: 1;
	top: 0;
	left: 0;

	.display {
		display: block;
	}
}
</style>

<script>
export default {
	props: {
		multipleBlocks: {
			type: Boolean,
			default: false,
			required: false
		},
		enabled: {
			type: Boolean,
			default: true,
			required: false
		},
		vertical: {
			type: String,
			default: 'center',
			required: false
		},
		horizontal: {
			type: String,
			default: 'center',
			required: false
		}
	}
}
</script>
