<template>
	<div class="live-container">
		<div v-if="state.loading" v-tooltip.bottom="msg.loading">
			<font-awesome-icon class="loading-icon" icon="spinner" />
		</div>
		<div v-if="state.error" v-tooltip.bottom="state.error">
			<font-awesome-icon class="error-icon" icon="exclamation-triangle" />
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			msg: {
				loading: `Updating with new data...`
			}
		}
	},
	props: {
		state: {
			type: Object,
			default: function() {
				return {
					loading: false,
					error: null
				}
			}
		}
	}
}
</script>

<style lang="scss">
.live-container {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 5;

	> div {
		padding: 0 10px 10px 10px;

		&:first-child {
			padding-top: 10px;
		}
	}

	.error-icon {
		color: #ffcd00;
	}
}

@keyframes rotating-icon {
	0% {
		opacity: 1;
		transform: rotateZ(0deg);
	}
	50% {
		opacity: 0.9;
		transform: rotateZ(180deg);
	}
	100% {
		opacity: 1;
		transform: rotateZ(360deg);
	}
}
</style>
