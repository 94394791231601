<template>
	<div class="full info-display">
		<center-display :multiple-blocks="true" horizontal="center" vertical="center">
			<div class="icon-container">
				<icon :icon="current.icon" :color="current.color" />
			</div>
			<div class="text-container">
				<span class="title" :style="{ color: current.color }" v-html="current.title" />
				<span
					class="description"
					v-if="current.desc && current.desc !== 'undefined'"
					v-html="current.desc"
				/>
			</div>
		</center-display>
	</div>
</template>

<style lang="scss">
.info-display {
	.icon-container {
		display: inline-block;
		width: 40px;
		text-align: center;
		font-size: 32px;
		margin-right: 10px;
	}
	.text-container {
		display: inline-block;

		.title {
			display: block;
			font-size: 16px;
			font-weight: 600;
		}

		.description {
			display: block;
			font-size: 12px;
			font-weight: 400;
			text-transform: uppercase;
			opacity: 0.6;
			color: #ffffff;
		}
	}
}
</style>

<script>
import CenterDisplay from '@/components/Misc/CenterDisplay'
import Icon from '@/components/Misc/Icon'
import LiveComponent from '@/components/Data/LiveComponent'

// TODO - make this generic
const TYPE_MATCHERS = {
	match: (a, b) => a == b,
	morethan: (a, b) => a > b,
	lessthan: (a, b) => a < b
}

export default {
	extends: LiveComponent,
	name: 'SingleDisplay',
	components: {
		CenterDisplay,
		Icon
	},

	data() {
		return {
			current: {
				title: '',
				desc: '-',
				color: '#ffffff',
				icon: 'circle'
			}
		}
	},

	watch: {
		data() {
			this.updateValues(this.data[this.config.value])
		}
	},

	methods: {
		getOptions() {
			return {
				title: '-',
				desc: '-',
				icon: 'circle',
				color: '#ffffff',
				conditions: []
			}
		},

		updateData(results) {
			let value = results[0].values[0][1]

			for (let i = 0; i < this.options.conditions.length; i++) {
				let condition = this.options.conditions[i]

				if (TYPE_MATCHERS[condition.type](value, condition.target)) {
					this.setCondition(condition)
					return
				}
			}

			this.setCondition({})
		},

		setCondition(data) {
			this.current.title = data.title !== undefined ? data.title : this.options.title
			this.current.desc = data.desc !== undefined ? data.desc : this.options.desc
			this.current.color = data.color !== undefined ? data.color : this.options.color
			this.current.icon = data.icon !== undefined ? data.icon : this.options.icon
		}
	}
}
</script>
