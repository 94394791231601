<template>
	<div class="full map-container">
		<img class="map" src="/img/netherlands_alt.png" />
		<!-- <img class="map map-property" src="/img/netherlands_amernet_test.png" /> -->
		<img
			class="map map-property"
			title="Amernet"
			:class="{ selected: $selected.local }"
			@click="toggleLocal()"
			src="/img/netherlands_amernet.png"
		/>
	</div>
</template>

<style lang="scss">
.map-container {
	display: flex;
	justify-content: center;
}

.map-property {
	cursor: pointer;
	clip-path: circle(35px at 165px 325px);

	&.selected {
		animation: blink 1s linear infinite;
	}
}

.map {
	height: 100%;
	position: absolute;
}

@keyframes blink {
	0% {
		filter: grayscale(0%);
	}
	50% {
		filter: grayscale(100%);
	}
	100% {
		filter: grayscale(0%);
	}
}
</style>

<script>
export default {
	name: 'Map',
	methods: {
		toggleLocal() {
			this.$selected.local = !this.$selected.local
			this.$eventHub.$emit('force-refresh')
		}
	}
}
</script>
