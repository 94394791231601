import moment from 'moment'

export default [
	{
		name: 'Last 15 minutes',
		start: () => moment().subtract(15, 'minutes'),
		end: () => moment(),
		live: true
	},
	{
		name: 'Last hour',
		start: () => moment().subtract(1, 'hours'),
		end: () => moment(),
		live: true
	},
	{
		name: 'Last 6 hours',
		start: () => moment().subtract(6, 'hours'),
		end: () => moment(),
		live: true
	},
	{
		name: 'Last 24 hours',
		start: () => moment().subtract(24, 'hours'),
		end: () => moment(),
		live: true
	},
	{
		name: 'Today so far',
		start: () => moment().startOf('day'),
		end: () => moment(),
		live: true
	},
	{
		name: 'Yesterday',
		start: () =>
			moment()
				.subtract(1, 'days')
				.startOf('day'),
		end: () =>
			moment()
				.subtract(1, 'days')
				.endOf('day'),
		live: false
	},
	{
		name: 'Last week',
		start: () =>
			moment()
				.subtract(7, 'days')
				.startOf('isoweek')
				.startOf('day'),
		end: () =>
			moment()
				.subtract(7, 'days')
				.endOf('isoweek')
				.endOf('day'),
		live: false
	},
	{
		name: 'Last month',
		start: () =>
			moment()
				.subtract(1, 'months')
				.startOf('month')
				.startOf('day'),
		end: () =>
			moment()
				.subtract(1, 'months')
				.endOf('month')
				.endOf('day'),
		live: false
	},
	{
		name: 'Last year',
		start: () =>
			moment()
				.subtract(1, 'years')
				.startOf('year')
				.startOf('day'),
		end: () =>
			moment()
				.subtract(1, 'years')
				.endOf('year')
				.endOf('day'),
		live: false
	}
]
