<template>
	<div class="full">
		<apexchart
			ref="chart"
			v-show="ready"
			width="100%"
			height="100%"
			type="radialBar"
			:options="options"
			:series="series"
		></apexchart>
		<center-display>
			<formatter :options="format" :data="rawValue" :min="config.min" :max="config.max" />
		</center-display>
	</div>
</template>

<script>
import CenterDisplay from '@/components/Misc/CenterDisplay'
import Formatter from '@/components/Misc/Formatter'
import LiveComponent from '@/components/Data/LiveComponent'

export default {
	extends: LiveComponent,
	name: 'Gauge',
	components: { CenterDisplay, Formatter },
	data() {
		return {
			labels: {},
			series: [0],
			range: 100,
			rawValue: 0
		}
	},

	beforeMount() {
		if (this.config.min !== undefined && this.config.max !== undefined) {
			this.range = Math.abs(this.config.max - this.config.min)
		}
	},

	methods: {
		getOptions() {
			return {
				plotOptions: {
					radialBar: {
						startAngle: 0,
						endAngle: 360,
						hollow: {
							margin: 0,
							size: '68%'
						},
						track: {
							background: 'rgba(255, 255, 255, 0.1)'
						},
						dataLabels: {
							name: {
								show: false
							},
							value: {
								show: false
							}
						}
					}
				},
				stroke: {
					lineCap: 'round'
				},
				labels: ['Cricket']
			}
		},

		updateData(results) {
			let value = results[0].values[0][1]
			let series = ((value - this.config.min) / this.range) * 100
			this.rawValue = value

			this.$refs.chart.updateSeries([series])

			for (let i = 0; i < this.config.ranges.length; ++i) {
				let range = this.config.ranges[i]
				if (value <= range.endValue) {
					this.$refs.chart.updateOptions({ colors: [range.color] }, true)
					break
				}
			}
		}
	}
}
</script>
