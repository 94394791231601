<template>
	<div class="center-warning">
		<font-awesome-icon class="icon" icon="book-dead" />
		<h3>
			Not Found
		</h3>
		<div class="content" v-if="id !== undefined && id.length > 0">
			Sorry, we were unable to find anything matching ID <kbd>{{ id }}</kbd
			>. You either you do not have access to this page or it does not exist.
		</div>
		<div class="content" v-else>
			The given ID is missing or invalid. Please make sure that the ID in the URL is correct.
		</div>
	</div>
</template>

<script>
export default {
	props: {
		id: {
			type: String,
			default: undefined
		}
	}
}
</script>
