<template>
	<div :key="renderCount">
		<grid-layout
			v-if="layout && !hibernate"
			:layout.sync="layout"
			:col-num="36"
			:cols="{ lg: 36, md: 30, sm: 18, xs: 12, xxs: 6 }"
			:row-height="30"
			:is-draggable="true"
			:is-resizable="true"
			:is-mirrored="false"
			:vertical-compact="true"
			:margin="[15, 15]"
			:use-css-transforms="true"
			:responsive="true"
			@layout-updated="updateLocalStorage"
		>
			<grid-item
				v-for="item in layout"
				:key="item.i"
				:x="item.x"
				:y="item.y"
				:w="item.w"
				:h="item.h"
				:min-w="item.minW"
				:min-h="item.minH"
				:i="item.i"
				drag-ignore-from=".item-content"
			>
				<div class="item-title">
					<icon style="margin-right: 5px" :icon="item.icon" />
					{{ item.title }}
				</div>
				<loading-indicator :state="item.state" />
				<div class="item-content">
					<component
						:config="item.config"
						:is="item.component"
						:id="item.i"
						:hibernate="hibernate"
						:misc="misc"
						:site="id"
						:page="page"
						v-on:loading="item.state = { loading: true }"
						v-on:loaded="item.state = {}"
						v-on:error="e => (item.state = { error: e })"
					/>
				</div>
			</grid-item>
		</grid-layout>
		<!-- <div @click="resetGridLayout()" class="grid-reset" v-if="customLayout">
			<font-awesome-icon class="icon" icon="table" />
			Reset grid layout
		</div> -->
	</div>
</template>

<script>
import Backend from '@/services/Backend'

import VueGridLayout from 'vue-grid-layout'

import LoadingIndicator from '@/components/Misc/LoadingIndicator'

import Amernet from '@/components/Amernet'
import Map from '@/components/Data/Map'
import Pie from '@/components/Data/Pie'
import BarGraph from '@/components/Data/BarGraph'
import Gauge from '@/components/Data/Gauge'
import LineGraph from '@/components/Data/LineGraph'
import WindRose from '@/components/Data/WindRose'
import CircleDisplay from '@/components/Data/CircleDisplay'
import SingleDisplay from '@/components/Data/SingleDisplay'
import InfoDisplay from '@/components/Data/InfoDisplay'
import Download from '@/components/Misc/Download'
import CustomTable from '@/components/Data/CustomTable'
import Icon from '@/components/Misc/Icon'

export default {
	props: {
		id: {
			type: String,
			required: true
		},
		page: {
			type: String,
			required: true
		},
		hibernate: {
			type: Boolean,
			default: false
		},
		misc: {
			type: Object,
			required: false,
			default: () => {
				return {}
			}
		}
	},
	components: {
		Icon,
		LoadingIndicator,
		Amernet,
		Map,
		Pie,
		BarGraph,
		Gauge,
		LineGraph,
		WindRose,
		Download,
		CircleDisplay,
		SingleDisplay,
		InfoDisplay,
		CustomTable,
		GridLayout: VueGridLayout.GridLayout,
		GridItem: VueGridLayout.GridItem
	},
	data() {
		return {
			layout: null,
			customLayout: false,
			renderCount: 0
		}
	},
	watch: {
		$route() {
			this.initLayout()
		}
	},
	created() {
		this.initLayout()
	},
	methods: {
		resetGridLayout() {
			let reset = confirm(
				'Are you sure you would like to reset all grid items to their original position and sizes?'
			)
			if (!reset) return

			try {
				let storage = JSON.parse(localStorage.getItem('windflex'))
				if (storage[this.id]) {
					delete storage[this.id][this.page]
				}
				this.customLayout = false
				localStorage.setItem('windflex', JSON.stringify(storage))
			} catch (e) {
				alert('Something went wrong trying to reset the grid layout.')
				console.error(e)
				return false
			}

			this.initLayout()
		},
		initLayout() {
			const layout = this.$route.meta ? this.$route.meta.layout : undefined
			switch (layout) {
				case 'dashboard_amernet':
					this.$topbar.showDashboardAmernet()
					break
				default:
					this.$topbar.showDashboard()
					break
			}
			this.layout = Backend.getLayout(layout)
			this.renderCount++
		},
		updateLocalStorage() {
			try {
				let newLayout = {}
				for (let i = 0; i < this.layout.length; i++) {
					let target = this.layout[i]
					newLayout[target.i] = {
						x: target.x,
						y: target.y,
						w: target.w,
						h: target.h
					}
				}

				let storage = JSON.parse(localStorage.getItem('windflex'))
				if (!storage[this.id]) {
					storage[this.id] = {
						[this.page]: newLayout
					}
				} else {
					storage[this.id][this.page] = newLayout
				}
				this.customLayout = true
				localStorage.setItem('windflex', JSON.stringify(storage))
			} catch (e) {
				console.error('Unable to locally store the page layout.')
				return false
			}
		}
	}
}
</script>
