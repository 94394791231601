<template>
	<span class="value-formatter">
		<span v-if="showPrefix" class="sub">{{ options.prefix }}</span>
		<span class="main">{{ mainData }}</span>
		<span v-if="showSuffix" class="sub">{{ options.suffix }}</span>
	</span>
</template>

<script>
export default {
	name: 'Formatter',
	props: {
		options: {
			type: Object,
			required: true
		},
		data: {
			required: true
		},
		min: {
			required: false
		},
		max: {
			required: false
		}
	},
	methods: {
		round(data, decimals) {
			const DECIMALS = decimals !== undefined ? decimals : 2
			return Number(data.toFixed(DECIMALS))
		},
		percentage(data, min, max) {
			return ((data - min) / Math.abs(min - max)) * 100
		}
	},
	computed: {
		showPrefix() {
			return this.options.prefix !== undefined && this.options.prefix.length
		},
		showSuffix() {
			return this.options.suffix !== undefined && this.options.suffix.length
		},
		mainData() {
			let data = this.data
			if (isNaN(data)) {
				return data
			}

			if (this.options.modifier !== undefined) {
				data = data * this.options.modifier
			}

			if (this.options.percentage !== undefined && this.options.percentage) {
				data = this.percentage(data, this.min, this.max)
			}

			data = this.round(data, this.options.decimals)

			if (this.options.outOf !== undefined && this.options.outOf) {
				data = `${data} / ${this.max}`
			} else {
				data = Number(data).toLocaleString('nl', {
					minimumFractionDigits: 0
				})
			}

			return data
		}
	}
}
</script>

<style lang="scss">
.value-formatter {
	> span {
		margin: 0 5px;
		:first-child {
			margin-left: 0;
		}
		:last-child {
			margin-right: 0;
		}
	}

	.main {
		display: inline-block;
		font-size: 24px;
		font-weight: 700;
		height: 0;
	}

	.sub {
		margin: 0;
		font-size: 18px;
		opacity: 0.6;
		display: inline-block;
	}
}
</style>
