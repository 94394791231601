<template>
	<div class="full">
		<apexchart
			ref="chart"
			v-show="ready"
			width="100%"
			height="100%"
			type="line"
			:options="options"
			:series="series"
		></apexchart>
	</div>
</template>

<script>
import LiveComponent from '@/components/Data/LiveComponent'

export default {
	extends: LiveComponent,
	name: 'LineGraph',
	data() {
		return {
			series: [],
			rawSeries: [],
			collapsed: {}
		}
	},
	methods: {
		getOptions() {
			return {
				stroke: {
					width: 1
				},
				chart: {
					animations: {
						enabled: false
					},
					events: {
						legendClick: (ctx, seriesIdx) => {
							// Toggle the current one
							let name = this.series[seriesIdx].name
							this.collapsed[name] = !this.collapsed[name]

							// Update the chart if it was turned ON (needs to be re-rendered in)
							if (!this.collapsed[name]) {
								this.updateSeries()
							}
						},
						zoomed: (chartContext, { xaxis }) => {
							this.$eventHub.$emit('update-date-range', {
								startDate: xaxis.min,
								endDate: xaxis.max
							})
						},
						updated: () => {
							// Re-collapse the series that are collapsed
							Object.keys(this.collapsed)
								.filter(e => this.collapsed[e])
								.forEach(e => this.$refs.chart.hideSeries(e))
						}
					}
				}
			}
		},

		updateData(results) {
			let series = []
			let colors = []
			for (let i = 0; i < results.length; ++i) {
				let serie = results[i]
				for (let j = 1; j < serie.columns.length; ++j) {
					let column = serie.columns[j]
					let cfg = this.seriesConfig[column]
					if (!cfg) {
						continue
					}

					colors.push(cfg.color)

					series.push({
						name: cfg.label,
						format: cfg.format,
						data: serie.values.map(e => [e[0], e[j]])
					})
				}
			}

			this.rawSeries = series

			this.$refs.chart.updateOptions({ colors })

			this.updateSeries()
		},

		updateSeries() {
			// Reset the data for the collapsed series to none so it doesn't draw
			this.series = this.rawSeries.map(s =>
				this.collapsed[s.name] ? { name: s.name, data: [] } : s
			)
		}
	}
}
</script>
