<template>
	<component :is="tag" @click.native="hideSidebar" class="nav-item" v-bind="$attrs" tag="li">
		<a class="nav-link">
			<slot>
				<icon :icon="icon" :custom-icon="customIcon" class="icon" />
				<div class="text-container">
					<p>{{ name }}</p>
					<p class="desc" v-if="desc">{{ desc }}</p>
				</div>
			</slot>
		</a>
	</component>
</template>

<script>
import Icon from '@/components/Misc/Icon'

export default {
	name: 'sidebar-link',
	components: { Icon },
	inheritAttrs: false,
	inject: {
		autoClose: {
			default: true
		},
		addLink: {
			default: () => {}
		},
		removeLink: {
			default: () => {}
		}
	},
	props: {
		name: String,
		desc: String,
		icon: {
			required: false
		},
		customIcon: {
			type: Boolean,
			required: false,
			default: false
		},
		tag: {
			type: String,
			default: 'router-link'
		}
	},
	methods: {
		hideSidebar() {
			if (this.autoClose) {
				this.$sidebar.displaySidebar(false)
			}
		},
		isActive() {
			return this.$el.classList.contains('active')
		}
	},
	beforeDestroy() {
		if (this.$el && this.$el.parentNode) {
			this.$el.parentNode.removeChild(this.$el)
		}
	}
}
</script>
