import Topbar from './Topbar.vue'
import DateRanges from '@/config/DateRanges'

const TopbarStore = {
	elements: {
		datepicker: true,
		liveToggle: true,
		refreshInterval: true,
		refreshButton: true,
		modelNavigation: {
			enabled: false,
			buttons: {
				totalsite: false,
				windfarm: false,
				battery: false
			}
		}
	},
	options: {
		live: true,
		refreshInterval: 10000,
		modelActiveNavigation: 'total_site',
		dateRange: DateRanges[2]
	},
	showSitesBar() {
		this.elements.datepicker = false
		this.elements.liveToggle = false
		this.elements.refreshInterval = true
		this.elements.modelNavigation.enabled = false
		this.elements.refreshButton = true
		this.options.live = true
		this.options.refreshInterval = 5000
		this.options.dateRange = DateRanges[0]
	},
	showDashboard() {
		this.elements.datepicker = false
		this.elements.liveToggle = true
		this.elements.refreshInterval = true
		this.elements.modelNavigation.enabled = false
		this.elements.refreshButton = true
		this.options.refreshInterval = 10000
		this.options.dateRange = DateRanges[2]
	},
	showDashboardAmernet() {
		this.elements.datepicker = false
		this.elements.liveToggle = false
		this.elements.refreshButton = true
		this.elements.refreshInterval = false
		this.elements.modelNavigation.enabled = true
		this.options.modelActiveNavigation = 'Overview'
		this.options.live = true
		this.options.refreshInterval = 10000
		this.options.dateRange = DateRanges[0]
	}
}

const TopbarPlugin = {
	install(Vue) {
		let app = new Vue({
			data: {
				topbarStore: TopbarStore
			}
		})

		Vue.prototype.$topbar = app.topbarStore
		Vue.component('top-bar', Topbar)
	}
}

export default TopbarPlugin
