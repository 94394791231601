export default [
	{
		id: 'amernet',
		name: 'Amernet',
		location: 'Breda, Tilburg, Geertruidenberg',
		country: 'The Netherlands'
	},
	{
		id: 'strijp',
		name: 'Strijp',
		location: 'Eindhoven',
		country: 'The Netherlands'
	},
	{
		id: 'meerhoven',
		name: 'Meerhoven',
		location: 'Eindhoven',
		country: 'The Netherlands'
	},
	{
		id: 'groene_net',
		name: 'Het Groene Net',
		location: 'Sittard',
		country: 'The Netherlands'
	},
	{
		id: 'sittard_hoogveld',
		name: 'Sittard Hoogveld',
		location: 'Sittard',
		country: 'The Netherlands'
	},
	{
		id: 'enschede',
		name: 'Enschede',
		location: 'Enschede',
		country: 'The Netherlands'
	},
	{
		id: 'helmond',
		name: 'Helmond',
		location: 'Helmond',
		country: 'The Netherlands'
	},
	{
		id: 'camminghaburen',
		name: 'Camminghaburen',
		location: 'Leeuwarden',
		country: 'The Netherlands'
	},
	{
		id: 'de_zuidlanden',
		name: 'De Zuidlanden',
		location: 'Leeuwarden',
		country: 'The Netherlands'
	}
]
