<template>
	<div class="full">
		<center-display :multiple-blocks="true" horizontal="center">
			<div class="circle-wrapper single">
				<div class="circle-outer">
					<div class="circle-inner">
						<div class="circle-inner-line" :style="{ 'border-color': options.color }">
							<center-display class="icon" :style="{ transform: iconRotation }">
								<component v-if="customIcon" :is="options.icon" />
								<font-awesome-icon v-else :icon="options.icon" />
							</center-display>
						</div>
					</div>
				</div>
			</div>
			<div class="single-value">
				<formatter :options="format" :data="data" />
			</div>
		</center-display>
	</div>
</template>

<script>
import CenterDisplay from '@/components/Misc/CenterDisplay'
import Formatter from '@/components/Misc/Formatter'
import LiveComponent from '@/components/Data/LiveComponent'

export default {
	extends: LiveComponent,
	name: 'SingleDisplay',
	components: {
		Formatter,
		CenterDisplay
	},

	data() {
		return {
			data: 0
		}
	},

	computed: {
		customIcon() {
			return ['battery', 'grid', 'grid_fat', 'power', 'windturbine'].includes(this.icon)
		},

		iconRotation() {
			if (this.options.rotateIcon.enabled) {
				return `rotateZ(${(this.data || 0) - this.options.rotateIcon.iconOffset}deg)`
			} else {
				return `0deg`
			}
		}
	},
	methods: {
		getOptions() {
			return {
				rotateIcon: {
					enabled: false,
					iconOffset: 0
				},
				icon: 'circle',
				color: '#ffffff'
			}
		},

		updateData(results) {
			this.data = results.value
		}
	}
}
</script>
