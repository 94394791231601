<template>
	<div :class="{ 'nav-open': $sidebar.showSidebar }" :data-theme="theme">
		<transition name="fadeout">
			<loading-overlay
				:message="loadingMsg"
				:sub-message="loadingSubMsg"
				:error="loadingError"
				v-if="!$store.state.sites.loaded"
			/>
			<div class="dashboard wrapper" v-else>
				<side-bar>
					<template slot="links">
						<div class="back-menu">
							<sidebar-link to="/dashboard" name="Go Back" icon="arrow-left" />
						</div>

						<sidebar-link
							to="/asset/amernet/dashboard"
							name="Dashboard"
							icon="tachometer-alt"
						/>
						<sidebar-link
							to="/asset/amernet/asset_monitor"
							name="Asset Monitor"
							icon="industry"
						/>
						<sidebar-link
							to="/asset/amernet/connections"
							name="Connections"
							icon="list"
						/>
						<sidebar-link
							to="/asset/amernet/financial"
							name="Financial"
							icon="euro-sign"
						/>
						<sidebar-link
							to="/asset/amernet/downloads"
							name="Downloads"
							icon="file-download"
						/>
						<div class="special-menu">
							<sidebar-link
								to="/asset/amernet/manual_import"
								name="Manual Import"
								icon="file-import"
							/>
							<sidebar-link
								to="/asset/amernet/changelog"
								name="Log of Changes"
								icon="clipboard-list"
							/>
						</div>
					</template>
				</side-bar>
				<div class="main-panel">
					<top-bar />
					<div class="content" style="margin-top: 115px" @click="toggleSidebar">
						<div class="container-fluid">
							<transition name="fade" mode="out-in">
								<router-view />
							</transition>
						</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import LoadingOverlay from '@/layout/misc/LoadingOverlay.vue'
import MobileMenu from '@/layout/dashboard/MobileMenu'
import Backend from '@/services/Backend'

export default {
	components: {
		MobileMenu,
		LoadingOverlay
	},
	props: {
		theme: {
			type: String,
			required: true
		}
	},
	methods: {
		toggleSidebar() {
			if (this.$sidebar.showSidebar) {
				this.$sidebar.displaySidebar(false)
			}
		},
		localStorageTest() {
			try {
				if (!localStorage.getItem('windflex')) {
					localStorage.setItem('windflex', '{}')
				}
				return true
			} catch (e) {
				return false
			}
		}
	},
	data() {
		return {
			loadingMsg: '',
			loadingSubMsg: '',
			loadingError: false
		}
	},
	created() {
		this.localStorageTest()
		this.loadingMsg = 'Loading'
		this.loadingSubMsg = 'Loading sites'
		this.$store.commit('sites/setSites', Backend.getSites())
	}
}
</script>

<style lang="scss">
.back-menu {
	margin-bottom: 10px;
	width: 100%;

	.nav-item:first-child {
		border-top: 1px solid #2b2b2b;
	}
	.nav-item {
		border-top: 1px solid transparent;

		.nav-link {
			color: #ffffff !important;
			justify-content: center;

			.icon {
				color: #ffffff !important;
				fill: #ffffff;
			}
		}
	}
}

.special-menu {
	margin-top: 10px;
	width: 100%;

	.nav-item:first-child {
		border-top: 1px solid #2b2b2b;
	}
	.nav-item {
		border-top: 1px solid transparent;

		.nav-link {
			color: #ffcd00 !important;

			.icon {
				color: #ffcd00 !important;
				fill: #ffcd00;
			}
		}
	}
}

.fadeout-enter-active {
	animation: fade-in 0.5s;
}
.fadeout-leave-active {
	animation: fade-keep 0.5s;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.1s;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fade-keep {
	100% {
		opacity: 1;
	}
}

.help-icon {
	cursor: help;
}

.daterangepicker {
	.ranges {
		color: #383838;
	}

	tr th {
		color: #383838;
	}

	tr td {
		color: #383838;
		&.off.in-range {
			background-color: #fff9de;
		}
		&.active {
			background-color: #357ebd !important;
			color: #ffff !important;
		}
	}
}

.content .container-fluid {
	padding-left: 0;
	padding-right: 0;
	&.static {
		margin-top: 10px;
	}
}

.loading-icon {
	color: #ffffff;
	animation: rotating-icon 1s linear infinite;
}

.full {
	height: 100%;
	width: 100%;
	position: relative;
}

.vue-grid-layout {
	.vue-grid-item {
		border: 1px solid #666666;
		border-radius: 5px;
		background: #383838;
		padding: 10px;
		position: relative;

		&.white-bg {
			background-image: linear-gradient(to bottom right, #ffffff, #ecf0f1);
			border: 1px solid #ecf0f1;
			color: #383838;
		}

		&.green-bg {
			background-image: linear-gradient(to bottom right, #00af62, #007d46);
			color: #fff;
		}
		&.yellow-bg {
			background-image: linear-gradient(to bottom right, #ffcc4a, #f8a21f);
			color: #fff;
		}
		&.orange-bg {
			background-image: linear-gradient(to bottom right, #f5952a, #e45b25);
			color: #fff;
		}
		&.red-bg {
			background-image: linear-gradient(to bottom right, #ff4632, #c93727);
			color: #fff;
		}

		.item-title {
			width: 100%;
			font-weight: 500;
			font-size: 16px;
			padding-bottom: 5px;

			.widget-icon {
				margin-right: 5px;
			}
		}

		.item-content {
			height: calc(100% - 30px);
			position: relative;

			&.disabled {
				opacity: 0.5;
				filter: grayscale(100%);
			}
		}

		&:hover {
			z-index: 1;
		}
	}
	.vue-grid-item > .vue-resizable-handle {
		padding: 0 5px 5px 0;
		opacity: 0.5;
	}
	.vue-draggable-dragging {
		border: 1px solid rgba(255, 255, 255, 0.2);
	}
	.vue-grid-item.vue-grid-placeholder {
		background: rgba(38, 38, 38, 0.4);
		z-index: -1;
	}
}
</style>
