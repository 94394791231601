<template>
	<div class="full spread">
		<div
			class="item-title"
			style="margin-top: -5px"
			v-show="$topbar.options.modelActiveNavigation == 'Overview'"
		>
			<icon style="margin-right: 5px" icon="chart-bar" />
			Production, consumption and heat loss overview for each month of 2020
		</div>
		<div
			class="item-title"
			style="margin-top: -5px"
			v-show="$topbar.options.modelActiveNavigation == 'Energy Sankey'"
		>
			<icon style="margin-right: 5px" icon="wave-square" />
			Sankey diagram with energy flows for Amernet
		</div>
		<div class="content" v-show="$topbar.options.modelActiveNavigation == 'Overview'">
			<div class="full">
				<apexchart
					type="bar"
					height="100%"
					v-show="ready"
					:options="bar.chartOptions"
					:series="bar.series"
				/>
			</div>
		</div>
		<div class="content" v-show="$topbar.options.modelActiveNavigation == 'Energy Sankey'">
			<div class="full">
				<div class="full" ref="sankey" />
			</div>
		</div>
	</div>
</template>

<style lang="scss">
g g g g text {
	fill: #ffffff;
}

.spread {
	display: flex;
	flex-direction: column;

	.content {
		flex-grow: 1;
		position: relative;
		height: 700px;
	}
}

.menu {
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-bottom: 15px;

	.option {
		text-align: center;
		flex-grow: 1;
		border: 1px solid #666666;
		border-radius: 0;
		background: rgba(0, 0, 0, 0.1);
		padding: 10px;
		cursor: pointer;
		font-weight: 600;

		&.selected {
			background: rgba(0, 0, 0, 0.3);
		}

		&:first-child {
			border-radius: 5px 0 0 5px;
		}

		&:last-child {
			border-radius: 0 5px 5px 0;
		}
	}
}
</style>

<script>
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import Icon from '@/components/Misc/Icon'

const $n = new Intl.NumberFormat('nl-NL', { maximumSignificantDigits: 2 })

am4core.addLicense('CH208084171')
am4core.useTheme(am4themes_animated)

export default {
	components: { Icon },
	data() {
		return {
			ready: false,
			bar: {
				chartOptions: {
					chart: {
						background: 'transparent',
						stacked: true,
						type: 'bar',
						height: 350
					},
					plotOptions: {
						bar: {
							horizontal: false,
							columnWidth: '40%'
						}
					},
					dataLabels: {
						enabled: false
					},
					stroke: {
						show: true,
						width: 2,
						colors: ['transparent']
					},
					theme: {
						mode: 'dark'
					},
					xaxis: {
						categories: [
							'Jan. 2020',
							'Feb. 2020',
							'Mar. 2020',
							'Apr. 2020',
							'May. 2020',
							'Jun. 2020',
							'Jul. 2020',
							'Aug. 2020'
						]
					},
					yaxis: {
						title: {
							text: 'Consumption & Production'
						},
						labels: {
							formatter(val) {
								return $n.format(val) + ' GJ'
							}
						}
					},
					fill: {
						opacity: 1
					},
					tooltip: {
						y: {
							formatter(val) {
								return $n.format(val) + ' GJ'
							}
						}
					},
					colors: [
						'#FFCD00',
						'#A2CD3A',
						'#2A9B9B',
						'#277AA9',
						'#314388',
						'#7A3D87',
						'#BA3E87',
						'#C93727',
						'#E45B25',
						'#F8A21F',
						'#A36427'
					]
				},
				series: [
					{
						name: 'Amer Centrale',
						data: [487405, 307283, 423799, 237400, 198473, 117423, 155852, 134370]
					},
					{
						name: 'Ketelhuis Amer & De la Reijweg',
						data: [676.8, 72158.4, 2667.6, 1022.4, 0, 28972.8, 0, 1573.2]
					},
					{
						name: 'Bio-ketels PS Breda & PS Tilburg',
						data: [
							2655.723156,
							74191.36784,
							4693.177369,
							1036.8,
							205.2,
							10720.8,
							187.2,
							612
						]
					},
					{
						name: 'WKK RWZI Nieuwveer',
						data: [
							2789.534884,
							2838.035496,
							2885.465116,
							2931.793146,
							2984.516524,
							3036.413709,
							3089.320685,
							3119.461444
						]
					},
					{
						name: 'Heat Loss',
						data: [
							-41499.05804,
							-181422.8033,
							-160733.2425,
							-111360.9931,
							-99952.71652,
							-102884.0137,
							-103734.5207,
							-101037.6614
						]
					},
					{
						name: 'Tilburg',
						data: [-203366, -113167, -111701, -55669, -43422, -21808, -21070, -14931]
					},
					{
						name: 'Breda',
						data: [-155278, -68425, -73451, -32553, -24810, -11199, -9798, -6443]
					},
					{
						name: 'Oosterhout',
						data: [-12919, -233, -204, -109, -430, -412, -347, -208]
					},
					{
						name: 'Made',
						data: [-61504, -72740, -71713, -37215, -29245, -22453, -22518, -15771]
					},
					{
						name: 'Geertruidenberg',
						data: [-5383, -4676, -4573, -2263, -1691, -706, -763, -516]
					},
					{
						name: 'Waspik',
						data: [-13578, -15807, -11670, -3221, -2112, -691, -898, -768]
					}
				]
			}
		}
	},
	mounted() {
		this.createSankey()
		this.$nextTick(() => {
			this.ready = true
		})
	},
	methods: {
		createSankey() {
			const chart = am4core.create(this.$refs.sankey, am4charts.SankeyDiagram)

			chart.data = [
				{
					from: 'Bought heat Amer (Start)',
					to: 'Bought heat Amer',
					value: 2062005,
					color: '#92B639',
					labelText: 'Bought heat\nAmer power plant 09\n[bold]2.062.005 GJ[/]'
				},
				{
					from: 'Bought heat Nieuwveer (Start)',
					to: 'Bought heat Nieuwveer',
					value: 23675,
					color: '#92B639',
					labelText: 'Bought heat\nWKK RWZI Nieuwveer\n[bold]23.675 GJ[/]'
				},
				{
					from: 'Bought heat Amer',
					to: 'PS Amer',
					value: 2062005,
					color: '#999868'
				},
				// ---------- //
				{
					from: 'Natural Gas',
					to: 'Ketelhuis Amer',
					value: 116182 + 3085,
					color: '#FFCD00',
					labelText: 'Natural Gas [bold]119.267 GJ[/]'
				},
				{
					from: 'Bio Oil 1',
					to: 'Bio Oil 2',
					value: 40277 + 64214,
					color: '#2A9B9B',
					labelText: 'Bio Oil [bold]104.491 GJ[/]'
				},
				{
					from: 'Energy 1',
					to: 'Energy 2',
					value: 11826 + 3483 + 3681,
					color: '#A36427',
					labelText: 'Bought Electricity\n[bold]18.990 GJ[/]'
				},
				{
					from: 'Energy 2',
					to: 'PS Amer',
					value: 11826,
					color: '#A36427'
				},
				{
					from: 'Ketelhuis Amer',
					to: 'PS Amer',
					value: 107071,
					color: '#FFCD00',
					labelText: 'Ketelhuis Amer'
				},
				{
					from: 'Ketelhuis Amer',
					to: 'Ketelhuis De la Reijweg',
					value: 3085,
					color: '#999868',
					labelText: 'Ketelhuis De la Reijweg'
				},
				{
					from: 'Ketelhuis Amer',
					to: 'Conversion Losses 1',
					value: 9111,
					color: '#cccccc'
				},
				// ---------- //
				{
					from: 'PS Amer',
					to: 'PS Amer Transport',
					value: 2062005 + 107071 + 11826,
					color: '#ee7d22',
					labelText: 'PS Amer\n[bold]2.169.076 GJ[/]'
				},
				{
					from: 'Bio Oil 2',
					to: 'Bio Oil 3',
					value: 40277 + 64214,
					color: '#2A9B9B'
				},
				{
					from: 'Ketelhuis De la Reijweg',
					to: 'Conversion Losses 2',
					value: 247,
					color: '#cccccc'
				},
				{
					from: 'Conversion Losses 1',
					to: 'Conversion Losses 2',
					value: 9111,
					color: '#cccccc'
				},
				// ---------- //
				{
					from: 'PS Amer Transport',
					to: 'Heat Transport 2',
					value: 665856 + 882194 - 23675,
					color: '#999868'
				},
				{
					from: 'PS Amer Transport',
					to: 'Heat Transport 3',
					value: 495219,
					color: '#999868'
				},
				{
					from: 'Bio Oil 3',
					to: 'Bio Oil 4',
					value: 40277 + 64214,
					color: '#2A9B9B'
				},
				{
					from: 'PS Amer Transport',
					to: 'Distribution Losses PS Amer',
					value: 125806 + 11826 + 23675,
					color: '#FF3E44',
					labelText: 'Distribution Losses\n[bold]137.632 GJ[/]'
				},
				{
					from: 'Conversion Losses 2',
					to: 'Conversion Losses 3',
					value: 9111,
					color: '#cccccc'
				},
				// ---------- //
				{
					from: 'Heat Transport 2',
					to: 'PS Tilburg',
					value: 882194,
					color: '#ee7d22',
					labelText: 'PS Tilburg'
				},
				{
					from: 'Heat Transport 2',
					to: 'PS Breda',
					value: 665856 - 23675,
					color: '#ee7d22',
					labelText: 'PS Breda'
				},
				{
					from: 'Bought heat Nieuwveer',
					to: 'PS Breda',
					value: 23675,
					color: '#999868'
				},
				{
					from: 'Heat Transport 3',
					to: 'Heat Transport 4',
					value: 495219,
					color: '#999868'
				},
				{
					from: 'Bio Oil 4',
					to: 'Bio-ketels Tilburg',
					value: 64214,
					color: '#5C75AF',
					labelText: 'Bio-ketels Tilburg'
				},
				{
					from: 'Bio Oil 4',
					to: 'Bio-ketels Breda',
					value: 40277,
					color: '#5C75AF',
					labelText: 'Bio-ketels Breda'
				},
				{
					from: 'Conversion Losses 3',
					to: 'Conversion Losses 4',
					value: 9111,
					color: '#cccccc'
				},
				{
					from: 'Ketelhuis De la Reijweg',
					to: 'PS Breda',
					value: 2838,
					color: '#FFCD00'
				},
				{
					from: 'Energy 2',
					to: 'PS Breda',
					value: 3483,
					color: '#D1DE4D'
				},
				{
					from: 'Energy 2',
					to: 'PS Tilburg',
					value: 3681,
					color: '#D1DE4D'
				},
				// ---------- //
				{
					from: 'PS Tilburg',
					to: 'City Tilburg',
					value: 939773 + 3681,
					color: '#F8A21F',
					labelText: 'Tilburg\n[bold]943.454 GJ[/]'
				},
				{
					from: 'PS Breda',
					to: 'City Breda',
					value: 728484 + 3483 - 23675,
					color: '#F8A21F',
					labelText: 'Breda\n[bold]731.967 GJ[/]'
				},
				{
					from: 'Bio-ketels Tilburg',
					to: 'PS Tilburg',
					value: 57579,
					color: '#919936'
				},
				{
					from: 'Bio-ketels Tilburg',
					to: 'Conversion Losses 4',
					value: 6635,
					color: '#cccccc'
				},
				{
					from: 'Bio-ketels Breda',
					to: 'PS Breda',
					value: 36115,
					color: '#919936'
				},
				{
					from: 'Bio-ketels Breda',
					to: 'Conversion Losses 4',
					value: 4162,
					color: '#cccccc'
				},
				{
					from: 'Heat Transport 4',
					to: 'Made',
					value: 394742,
					color: '#F8A21F',
					labelText: 'Made\n[bold]394.742 GJ[/]'
				},
				{
					from: 'Heat Transport 4',
					to: 'Waspik',
					value: 58494,
					color: '#F8A21F',
					labelText: 'Waspik'
				},
				{
					from: 'Heat Transport 4',
					to: 'Geertruidenberg',
					value: 24374,
					color: '#F8A21F',
					labelText: 'Geertruidenberg'
				},
				{
					from: 'Heat Transport 4',
					to: 'Oosterhout',
					value: 17609,
					color: '#F8A21F',
					labelText: 'Oosterhout'
				},
				// ----- //
				{
					from: 'City Tilburg',
					to: 'Commercial Tilburg',
					value: 240501,
					color: '#DD48FF',
					labelText: 'Commercial\n[bold]240.501 GJ[/]'
				},
				{
					from: 'City Tilburg',
					to: 'Residential Tilburg',
					value: 344633,
					color: '#5C7DFF',
					labelText: 'Residential\n[bold]344.633 GJ[/]'
				},
				{
					from: 'City Tilburg',
					to: 'Distribution Losses Tilburg',
					value: 358321 + 3681,
					color: '#FF3E44',
					labelText: 'Distribution Losses\n[bold]362.002 GJ[/]'
				},
				{
					from: 'City Breda',
					to: 'Commercial Breda',
					value: 215137,
					color: '#DD48FF',
					labelText: 'Commercial\n[bold]215.137 GJ[/]'
				},
				{
					from: 'City Breda',
					to: 'Residential Breda',
					value: 166820,
					color: '#5C7DFF',
					labelText: 'Residential\n[bold]166.820 GJ[/]'
				},
				{
					from: 'City Breda',
					to: 'Distribution Losses Breda',
					value: 350010 + 3483  - 23675,
					color: '#FF3E44',
					labelText: 'Distribution Losses\n[bold]353.493 GJ[/]'
				},
				{
					from: 'Made',
					to: 'Horticulturist',
					value: 333159,
					color: '#58996A',
					labelText: 'Horticulturist\n[bold]333.159 GJ[/]'
				},
				{
					from: 'Made',
					to: 'Distribution Losses Made',
					value: 61583,
					color: '#FF3E44'
				},
				{
					from: 'Waspik',
					to: 'Residential Waspik',
					value: 48745,
					color: '#5C7DFF'
				},
				{
					from: 'Waspik',
					to: 'Distribution Losses Waspik',
					value: 9749,
					color: '#FF3E44'
				},
				{
					from: 'Geertruidenberg',
					to: 'Commercial Geertruidenberg',
					value: 9385,
					color: '#DD48FF'
				},
				{
					from: 'Geertruidenberg',
					to: 'Residential Geertruidenberg',
					value: 11186,
					color: '#5C7DFF'
				},
				{
					from: 'Geertruidenberg',
					to: 'Distribution Losses Geertruidenberg',
					value: 3803,
					color: '#FF3E44'
				},
				{
					from: 'Oosterhout',
					to: 'Commercial Oosterhout',
					value: 13829,
					color: '#DD48FF'
				},
				{
					from: 'Oosterhout',
					to: 'Residential Oosterhout',
					value: 1033,
					color: '#5C7DFF'
				},
				{
					from: 'Oosterhout',
					to: 'Distribution Losses Oosterhout',
					value: 2747,
					color: '#FF3E44'
				},
				{
					from: 'Conversion Losses 4',
					to: 'Conversion Losses 5',
					value: 9111 + 4162 + 6635,
					color: '#cccccc'
				},
				{
					from: 'Conversion Losses 5',
					to: 'Conversion Losses 6',
					labelText: 'Conversion Losses',
					value: 9111 + 4162 + 6635,
					color: '#cccccc'
				}
			]

			chart.nodePadding = 25
			chart.minNodeSize = 0.001
			chart.nodeAlign = 'right'
			chart.paddingLeft = 0
			chart.paddingRight = 0
			chart.dataFields.fromName = 'from'
			chart.dataFields.toName = 'to'
			chart.dataFields.value = 'value'
			chart.dataFields.color = 'color'

			chart.orientation = 'horizontal'
			chart.sortBy = 'none'

			chart.nodes.template.togglable = false

			let hoverState = chart.links.template.states.create('hover')
			hoverState.properties.fillOpacity = 0.85

			let linkTemplate = chart.links.template
			linkTemplate.fillOpacity = 0.7
			linkTemplate.colorMode = 'toNode'

			linkTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer
			linkTemplate.readerTitle = 'Drag me!'
			linkTemplate.showSystemTooltip = true
			linkTemplate.tooltipText = ''
			linkTemplate.propertyFields.zIndex = 'zIndex'
			linkTemplate.tension = 0.6
			console.log(chart)
			chart.nodes.template.nameLabel.label.fill = am4core.color('#fff')

			// linkTemplate.link = am4core.color('#fff')

			//dragging
			chart.links.template.events.on('down', event => {
				let fromNode = event.target.dataItem.fromNode
				let toNode = event.target.dataItem.toNode

				let distanceToFromNode = am4core.math.getDistance(event.pointer.point, {
					x: fromNode.pixelX,
					y: fromNode.pixelY
				})
				let distanceToToNode = Infinity
				if (toNode) {
					distanceToToNode = am4core.math.getDistance(event.pointer.point, {
						x: toNode.pixelX,
						y: toNode.pixelY
					})
				}

				if (distanceToFromNode < distanceToToNode) {
					fromNode.dragStart(event.pointer)
				} else {
					toNode.dragStart(event.pointer)
				}
			})

			chart.nodes.template.draggable = true
			chart.nodes.template.inert = true
			chart.nodes.template.width = 20
			chart.nodes.template.height = 0
			chart.nodes.template.nameLabel.disabled = true
			chart.nodes.template.clickable = false

			let labelBullet = chart.links.template.bullets.push(new am4charts.LabelBullet())
			labelBullet.label.propertyFields.text = 'labelText'
			labelBullet.propertyFields.locationX = 'labelLocation'
			labelBullet.propertyFields.rotation = 'labelRotation'
			labelBullet.label.rotation = 0
			labelBullet.propertyFields.dy = 'dy'
			labelBullet.label.propertyFields.horizontalCenter = 'center'
			labelBullet.label.textAlign = 'middle'

			this.chart = chart
		}
	}
}
</script>
