<template>
	<div class="full">
		<plotly
			v-show="ready"
			class="plotly"
			:data="data"
			:layout="layout"
			:display-mode-bar="false"
			:responsive="true"
		/>
	</div>
</template>

<style scoped>
.plotly {
	width: 100%;
	max-width: 100%;
	height: 100%;
	max-height: 100%;
}
</style>

<script>
import LiveComponent from '@/components/Data/LiveComponent'
import { Plotly } from 'vue-plotly'

const _DIRECTIONS = [
	0 + 22.5, // N
	45 + 22.5, // NE
	90 + 22.5, // E
	135 + 22.5, // SE
	180 + 22.5, // S
	225 + 22.5, // SW
	270 + 22.5, // W
	315 + 22.5, // NW
	360 // N2
]
const _DIRECTION_LABELS = ['North', 'N-E', 'East', 'S-E', 'South', 'S-W', 'West', 'N-W']
const _SPEEDS = [
	2.5, // 0 to 2.5 m/s
	5.0, // 2.5 to 5 m/s
	7.5, // 5 to 7.5 m/s
	10.0, // 7.5 to 10 m/s
	12.5, // 10 to 12.5 m/s
	15.0, // 12.5 to 15 m/s
	1000 // >15 m/s
]
const _SPEED_LABELS = [
	'0 to 2.5 m/s',
	'2.5 to 5 m/s',
	'5 to 7.5 m/s',
	'7.5 to 10 m/s',
	'10 to 12.5 m/s',
	'12.5 to 15 m/s',
	'>15 m/s'
]

export default {
	extends: LiveComponent,
	name: 'WindRose',
	components: { Plotly },
	data() {
		return {
			data: this.placeholder(),
			layout: {
				font: {
					size: 12,
					color: '#ffffff'
				},
				paper_bgcolor: 'transparent',
				legend: {
					bgcolor: 'transparent',
					font: {
						color: '#ffffff',
						size: 12
					},
					xanchor: 'bottom'
				},
				margin: {
					l: 0,
					r: 0,
					t: 40,
					b: 40,
					pad: 0
				},
				polar: {
					bgcolor: 'transparent',
					barmode: 'stack',
					bargap: 2,
					radialaxis: {
						hoverformat: '.2f',
						color: 'rgba(255, 255, 255, 0.8)',
						ticksuffix: '%',
						angle: 90,
						dtick: 25
					},
					angularaxis: {
						hoverformat: '.2f',
						color: 'rgba(255, 255, 255, 0.8)',
						direction: 'clockwise'
					}
				}
			}
		}
	},
	methods: {
		parseData(data) {
			const POINT_VALUE = 100 / data.values.length

			let res = this.placeholder()

			data.values.forEach(([_, windSpeed, windDir]) => {
				let is = _SPEEDS.findIndex(v => v >= windSpeed)
				let id = _DIRECTIONS.findIndex(v => v >= windDir)

				// The modulus sets N2 to N
				res[is].r[id % (_DIRECTIONS.length - 1)] += POINT_VALUE
			})

			return res
		},

		updateData(results) {
			this.data = this.parseData(results[0])
		},

		placeholder() {
			return _SPEED_LABELS.map((s, i) => ({
				name: s,
				theta: _DIRECTION_LABELS,
				r: [0, 0, 0, 0, 0, 0, 0, 0],
				marker: {
					color: this.config.series[i].color
				},
				type: 'barpolar'
			}))
		}
	}
}
</script>
