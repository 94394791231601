<template>
	<div class="full">
		<center-display
			:horizontal="options.horizontalCenter"
			:vertical="options.verticalCenter"
			:multiple-blocks="false"
		>
			<span v-if="options.description" v-html="options.description" class="desc-container" />
			<div style="display:inline-block;">
				<label>Year</label>
				<br />
				<select class="custom-dropdown" v-model="selection.year">
					<option v-for="year in years" :value="year">{{ year }}</option>
				</select>
			</div>
			<div style="margin-left: 15px;display:inline-block;">
				<label>Month</label>
				<br />
				<select v-model="selection.month" class="custom-dropdown">
					<option value="1">January</option>
					<option value="2">February</option>
					<option value="3">March</option>
					<option value="4">April</option>
					<option value="5">May</option>
					<option value="6">June</option>
					<option value="7">July</option>
					<option value="8">August</option>
					<option value="9">September</option>
					<option value="10">October</option>
					<option value="11">November</option>
					<option value="12">December</option>
				</select>
			</div>
			<br />
			<br />
			<p-button type="success" @click.native="download">
				Download CSV
			</p-button>
		</center-display>
	</div>
</template>

<script>
import CenterDisplay from '@/components/Misc/CenterDisplay'
import Backend from '@/services/Backend'
import moment from 'moment'

export default {
	name: 'Download',
	components: {
		CenterDisplay
	},
	props: ['id', 'config', 'misc', 'site', 'page'],
	data() {
		return {
			years: Array.from({ length: moment().year() - 2018 }, (_, i) => i + 2019),
			selection: {
				month: moment().month() + 1,
				year: moment().year()
			},
			data: undefined,
			options: Object.assign(
				{
					description: undefined,
					horizontalCenter: 'left',
					verticalCenter: 'top'
				},
				this.config
			)
		}
	},

	methods: {
		download() {
			window.open(
				Backend.getDownloadUrl(
					this.id,
					this.site,
					this.page,
					this.startDate(),
					this.endDate(),
					this.misc ? this.misc.queryString : ''
				)
			)
		},
		startDate() {
			return moment()
				.set('month', this.selection.month - 1)
				.set('year', this.selection.year)
				.startOf('month')
				.startOf('day')
		},
		endDate() {
			return moment()
				.set('month', this.selection.month - 1)
				.set('year', this.selection.year)
				.endOf('month')
				.endOf('day')
		}
	}
}
</script>
