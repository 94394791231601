import axios from 'axios'
import sites from '../data/sites'
import dashboard from '../data/dashboard'
import dashboard_amernet from '../data/dashboard_amernet'

const CancelToken = axios.CancelToken
const RETRIES = 3

function getCookieValue(a) {
	let b = document.cookie.match('(^|[^;]+)\\s*' + a + '\\s*=\\s*([^;]+)')
	return b ? b.pop() : ''
}

axios.defaults.baseURL = '/api/v1/'

// TODO - not this
axios.defaults.headers.common['Authorization'] = `Bearer ${getCookieValue('kc-access')}`

export default {
	request(method, url, data = null, retry = 0) {
		if (retry > 0) {
			console.warn(`Retry ${retry} for ${url}`)
		}

		let cancel = CancelToken.source()

		let promise = axios({
			method,
			url,
			data,
			cancelToken: cancel.token
		})
			.then(response => response.data)
			.catch(e => {
				if (axios.isCancel(e)) {
					return
				}

				if (retry < RETRIES) {
					return this.request(method, url, data, retry + 1)
				} else {
					throw new Error(`API call failed for '${url}', with ${e.name}: ${e.message}`)
				}
			})

		// Attach cancel function
		promise.cancel = cancel.cancel

		return promise
	},

	getTheme() {
		return 'spectral'
	},

	getSites() {
		return sites
	},

	getLayout(layout) {
		switch (layout) {
			case 'dashboard':
				return dashboard
			case 'dashboard_amernet':
				return dashboard_amernet
			default:
				return dashboard
		}
	},

	getValue(id, site, page, start, end, queryString = '') {
		return this.request(
			'get',
			`values/${site}/${page}/${id}/${start.toISOString()}/${end.toISOString()}${queryString}`
		)
	},

	getDownloadUrl(id, site, page, start, end, queryString = '') {
		return `${
			axios.defaults.baseURL
		}downloads/${site}/${page}/${id}/${start.toISOString()}/${end.toISOString()}${queryString}`
	}
}
