<template>
	<div v-if="$store.state.sites.loaded">
		<grid page="dashboard" :id="id" />
	</div>
	<invalid v-else :id="id" />
</template>

<script>
import Grid from '@/layout/dashboard/Grid'
import Invalid from '@/pages/Invalid'

export default {
	components: {
		Grid,
		Invalid
	},
	props: {
		id: {
			type: String,
			default: 'NONE'
		}
	}
}
</script>
