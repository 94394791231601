import Vue from 'vue'
import Vuex from 'vuex'
import sites from './modules/sites'
import createLogger from 'vuex/dist/logger'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
	modules: {
		sites
	},
	strict: debug,
	plugins: debug ? [createLogger()] : []
})
