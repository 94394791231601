<template>
	<div>
		<div class="sidebar">
			<div class="sidebar-wrapper" id="style-3">
				<div class="logo">
					<router-link to="/dashboard" class="simple-text center">
						<div class="logo-img">
							<img src="/img/theme/logo.svg" />
						</div>
					</router-link>
				</div>

				<slot name="mobileMenu"></slot>

				<div class="misc-wrapper">
					<ul class="nav">
						<div class="search" v-bind:class="{ searching: searching }">
							<div class="icon">
								<font-awesome-icon icon="search" />
							</div>
							<vue-fuse
								ref="searchBar"
								placeholder="Search Sites..."
								event-name="searchResults"
								:list="$store.state.sites.items"
								:keys="['name', 'location', 'country']"
								:threshold="0.2"
								class="search-input"
								@click.native="startSearching"
								@focus.native="startSearching"
								v-bind:class="{ searching: searching }"
							/>
						</div>
					</ul>
				</div>

				<div class="menu-wrapper">
					<!-- Default Menu -->
					<ul class="nav moveable" v-show="!hideDefaultView">
						<slot name="links">
							<sidebar-link
								v-for="(link, index) in sidebarLinks"
								:key="index"
								:to="link.path"
								:name="link.name"
								:icon="link.icon"
								@click="link.clickAction ? link.clickAction : ''"
							/>
						</slot>
					</ul>

					<!-- Detailed Menu -->
					<ul class="nav moveable" v-show="!hideDetailedView">
						<slot name="detailLinks">
							<sidebar-link
								v-for="(link, index) in sidebarLinks"
								:key="index"
								:to="link.path"
								:name="link.name"
								:icon="link.icon"
							/>
						</slot>
					</ul>
				</div>
			</div>
		</div>
		<div class="search-menu" :class="{ inactive: !searching }">
			<div class="background" @click="stopSearching"></div>
			<div class="container">
				<div class="content">
					<!-- Search Result Menu -->
					<ul class="nav">
						<sidebar-link
							v-for="(link, index) in searchResults"
							:key="index"
							:to="'/asset/amernet/dashboard'"
							@click.native="stopSearching"
							:name="link.name"
							:desc="`${link.location}, ${link.country}`"
							:icon="link.icon"
						/>
						<div class="no-results" v-if="searchResults.length === 0">
							<h3>No Search Results</h3>
							You can search by name or location.
						</div>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SidebarLink from './SidebarLink'
export default {
	props: {
		sidebarLinks: {
			type: Array,
			default: () => []
		},
		autoClose: {
			type: Boolean,
			default: true
		}
	},
	provide() {
		return {
			autoClose: this.autoClose,
			addLink: this.addLink,
			removeLink: this.removeLink
		}
	},
	components: {
		SidebarLink
	},
	data() {
		return {
			menuType: 'default',
			searching: false,
			searchResults: []
		}
	},
	created() {
		this.$on('searchResults', searchResults => {
			this.searchResults = searchResults
		})
	},
	computed: {
		hideDetailedView() {
			return this.$route.meta.menu !== 'specific'
		},
		hideDefaultView() {
			return this.$route.meta.menu !== 'default' && this.$route.meta.menu !== undefined
		}
	},
	methods: {
		stopSearching() {
			if (this.searching) {
				this.$refs.searchBar.value = ''
				this.searching = false
			}
		},
		startSearching() {
			this.searching = true
		}
	},
	beforeRouteUpdate() {
		this.stopSearching()
	},
	beforeRouteLeave() {
		this.stopSearching()
	}
}
</script>
