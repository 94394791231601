<template>
	<div class="container">
		<div class="text-center">
			<h2 class="title text-danger">404 Not Found</h2>
			<h2 class="title">Sorry, we were unable to locate this page.</h2>
		</div>
	</div>
</template>

<script>
export default {}
</script>
