export default [
	{
		i: 'amernet',
		component: 'Amernet',
		x: 0,
		y: 0,
		w: 36,
		h: 18,
		minW: 36,
		minH: 18,
		live: true,
		config: {}
	}
]
