<template>
	<div class="login-wrapper vue-grid-layout loading-wrapper">
		<div class="login-container">
			<font-awesome-icon v-if="!error" class="loading-icon icon" icon="spinner" />
			<font-awesome-icon v-else class="icon" icon="exclamation-triangle" />
			<div class="message">
				{{ message }}
				<div class="sub" v-show="subMessage.length > 0">{{ subMessage }}</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
.loading-wrapper {
	position: fixed !important;

	.icon {
		font-size: 32px;
	}

	.message {
		margin-top: 5px;

		.sub {
			position: relative;
			height: 0;
			opacity: 0.7;
		}
	}

	.login-container {
		text-align: center;
	}
}
</style>

<script>
export default {
	props: {
		message: {
			type: String,
			required: true
		},
		subMessage: {
			type: String,
			required: false,
			default: ''
		},
		error: {
			type: Boolean,
			required: false,
			default: false
		}
	}
}
</script>
