<template>
	<ul class="nav navbar-nav nav-mobile-menu">
		<slot></slot>
	</ul>
</template>

<script>
export default {}
</script>

<style></style>
