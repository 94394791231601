import Vue from './imports/vue'

import App from './App'
import router from './imports/router'
import store from './store'
import Backend from '@/services/Backend'

new Vue({
	router,
	store,
	render: h => h(App, { props: { theme: Backend.getTheme() } })
}).$mount('#app')

// TODO - clean up topbar - no plugin
// TODO - loading screen for chart things -- on('ready') for all charts
// TODO - subpages for model instead of hibernate
// TODO - don't use misc for passing querystring - use a store for the model state (after split)
// TODO - get rid of $model in favour of store
// TODO - graphs sometimes don't update on switching turbine (not live, not after)
// TODO - get rid of customIcon
