const state = {
	items: [],
	loaded: false
}

const mutations = {
	setSites(state, sites) {
		state.items = sites
		state.loaded = true
		state.error = false
	}
}

export default {
	namespaced: true,
	state,
	mutations
}
