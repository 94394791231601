<template>
	<div class="full">
		<apexchart
			ref="chart"
			v-show="ready"
			width="100%"
			height="100%"
			type="bar"
			:options="options"
			:series="series"
		/>
	</div>
</template>

<script>
import LiveComponent from '@/components/Data/LiveComponent'

export default {
	extends: LiveComponent,
	name: 'BarGraph',
	data() {
		return {
			type: 'bargraph',
			series: [],
			total: 0,
			format: Object.assign(
				{
					prefix: '',
					suffix: '',
					decimals: 2,
					round: false
				},
				this.config.format || {}
			)
		}
	},

	mounted() {
		this.$refs.chart.updateOptions({ colors: this.colors })
	},

	computed: {
		colors() {
			return this.config.series.map(e => e.color)
		}
	},

	methods: {
		getOptions() {
			return {
				chart: {
					type: 'bar',
					toolbar: {
						show: false
					},
					animations: {
						enabled: false
					}
				},
				plotOptions: {
					bar: {
						barHeight: '60%',
						distributed: true,
						horizontal: true,
						dataLabels: {
							position: 'bottom'
						}
					}
				},
				yaxis: {
					labels: {
						show: false
					}
				},
				xaxis: {
					type: 'category',
					labels: {
						show: false
					},
					axisTicks: {
						show: false,
						height: 0
					},
					categories: Object.keys(this.seriesConfig).map(
						val => this.seriesConfig[val].label
					)
				},
				dataLabels: {
					enabled: true,
					textAnchor: 'start',
					style: {
						colors: ['#fff']
					},
					formatter: this.formatter,
					offsetX: -25,
					dropShadow: {
						enabled: true
					}
				}
			}
		},

		updateData(results) {
			if (results === undefined) return
			if (this.config.handleDataDirectly) {
				this.$refs.chart.updateOptions(results)
				return
			}
			const series = []
			const colors = []

			for (const value of Object.keys(this.seriesConfig)) {
				const cfg = this.seriesConfig[value]
				if (!cfg) continue
				colors.push(cfg.color)
				series.push(results[value])
			}

			this.series = [{ name: 'Amount', data: series }]
			this.$refs.chart.updateOptions({ colors })
		}
	}
}
</script>
