<template>
	<font-awesome-icon :icon="icon" v-if="!customIcon && icon" :style="{ color: color }" />
	<inline-svg
		v-else-if="customIcon && icon"
		:src="'/img/icons/' + icon + '.svg'"
		width="100%"
		height="100%"
		:fill="color"
	/>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

export default {
	components: { InlineSvg },
	props: {
		icon: {
			type: String,
			required: false
		},
		color: {
			type: String,
			required: false,
			default: 'white'
		}
	},
	computed: {
		customIcon() {
			return ['battery', 'grid', 'grid_fat', 'power', 'windturbine'].includes(this.icon)
		}
	}
}
</script>
