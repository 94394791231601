import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
	faWaveSquare,
	faChartBar,
	faFileImport,
	faClipboardList,
	faMapMarkerAlt,
	faFileInvoice,
	faEuroSign,
	faIndustry,
	faEquals,
	faPowerOff,
	faSeedling,
	faList,
	faMap,
	faThermometerThreeQuarters,
	faFire,
	faNetworkWired,
	faBolt,
	faTable,
	faCircle,
	faStream,
	faExclamationTriangle,
	faSearch,
	faArrowLeft,
	faTimes,
	faTachometerAlt,
	faBookDead,
	faChevronRight,
	faArrowCircleRight,
	faChevronDown,
	faInfoCircle,
	faSignOutAlt,
	faBell,
	faSpinner,
	faCheck,
	faSync,
	faThermometerHalf,
	faLocationArrow,
	faFileDownload,
	faWind
} from '@fortawesome/free-solid-svg-icons'

library.add(
	faWaveSquare,
	faChartBar,
	faFileImport,
	faClipboardList,
	faMapMarkerAlt,
	faFileInvoice,
	faEuroSign,
	faIndustry,
	faEquals,
	faPowerOff,
	faSeedling,
	faList,
	faMap,
	faThermometerThreeQuarters,
	faFire,
	faNetworkWired,
	faBolt,
	faTable,
	faCircle,
	faStream,
	faExclamationTriangle,
	faSearch,
	faArrowLeft,
	faTimes,
	faTachometerAlt,
	faBookDead,
	faChevronRight,
	faArrowCircleRight,
	faChevronDown,
	faInfoCircle,
	faSignOutAlt,
	faBell,
	faSpinner,
	faCheck,
	faSync,
	faThermometerHalf,
	faLocationArrow,
	faFileDownload,
	faWind
)

export default FontAwesomeIcon
