<script>
import deepmerge from 'deepmerge'

export default {
	name: 'LiveComponent',
	props: ['id', 'config', 'hibernate', 'misc', 'site', 'page'],

	data() {
		return {
			ready: false,
			format: deepmerge(
				{
					prefix: '',
					suffix: '',
					decimals: 2,
					round: false
				},
				this.config.format || {}
			)
		}
	},

	computed: {
		seriesConfig() {
			return Object.assign(...this.config.series.map(e => ({ [e.value]: e })))
		},

		options() {
			let merged = deepmerge.all([
				{
					animations: {
						enabled: false
					},
					chart: {
						background: 'rgba(0, 0, 0, 0)',
						toolbar: {
							tools: {
								download: true,
								zoomin: false,
								zoomout: false,
								pan: false,
								reset: false
							}
						}
					},
					grid: {
						borderColor: 'rgba(255, 255, 255, 0.2)',
						borderWidth: 5
					},
					legend: {
						itemMargin: {
							vertical: 5
						}
					},
					tooltip: {
						followCursor: true,
						x: {
							format: 'd MMM yyyy, HH:mm:ss'
						}
					},
					theme: {
						mode: 'dark'
					},
					xaxis: {
						type: 'datetime',
						labels: {
							datetimeUTC: false,
							datetimeFormatter: {
								year: 'yyyy',
								month: "MMM 'yy",
								day: "d MMM 'yy",
								hour: 'HH:mm'
							}
						}
					},
					yaxis: {
						labels: {}
					},
					noData: {
						text: 'Loading...'
					}
				},
				this.getOptions(),
				this.config.options || {}
			])

			if (this.type === 'pie') {
				merged.labels = this.config.series.map(e => e.label)
			}

			// Set generic formatter for all yaxis'
			if (Array.isArray(merged.yaxis)) {
				for (const index in merged.yaxis) {
					if (!merged.yaxis[index].labels) merged.yaxis[index].labels = {}
					merged.yaxis[index].labels.formatter = this.formatter
				}
			} else {
				merged.yaxis.labels.formatter = this.formatter
			}
			return merged
		}
	},

	methods: {
		getOptions() {
			return {}
		},

		formatter(value, options) {
			if (value === undefined) return '-'
			if (
				options &&
				options.seriesIndex > -1 &&
				this.series[options.seriesIndex] &&
				this.series[options.seriesIndex].format
			) {
				let format = this.series[options.seriesIndex].format
				let number = value.toFixed(format.decimals || this.format.decimals)
				if (format.decimals || (format.decimals === undefined && this.format.round)) {
					number = Number(number)
				}
				return (
					(format.prefix || this.format.prefix) +
					number +
					(format.suffix || this.format.suffix)
				)
			} else {
				let number = value.toFixed(this.format.decimals)
				if (this.format.round) {
					number = Number(number)
				}
				return (
					this.format.prefix +
					Number(number).toLocaleString('nl', {
						minimumFractionDigits: this.format.decimals || 0
					}) +
					this.format.suffix
				)
			}
		},

		getData() {
			if (this.hibernate) {
				return
			}

			switch (this.id) {
				case 'electricity_produced':
					return this.$selected.local ? { value: 0 } : { value: 20000 }
				case 'co2_emissions':
					return this.$selected.local ? { value: 35.4 } : { value: 30 }
				case 'renewable_share':
					return this.$selected.local ? { value: 80 } : { value: 75 }
				case 'eor':
					return this.$selected.local ? { value: 363 } : { value: 410 }
				case 'primary_fossil_energy':
					return this.$selected.local ? { value: 0.48 } : { value: 0.4 }
				case 'number_of_connections':
					return this.$selected.local
						? { small_users: 46000, large_users: 375 }
						: { small_users: 60000, large_users: 30000 }
				case 'own_assets_vs_procured':
					return this.$selected.local
						? { own_assets: 597614, procured: 3106110 }
						: { own_assets: 1195228, procured: 4659165 }
				case 'revenues_generated':
					return this.$selected.local
						? { heat: 61111446, electricity: 3055572 }
						: { heat: 106842672.3, electricity: 5342134 }
				case 'costs_breakdown':
					return this.$selected.local
						? { budgetted: 8665488, actual: 8542032 }
						: { budgetted: 17330976, actual: 17084064 }
				case 'heat_production_consumption':
					return this.$selected.local
						? { production: 3703724, consumption: 2444458, losses: 1259266 }
						: { production: 5854393, consumption: 4273707, losses: 1580686 }
				case 'fuel_mix':
					return this.$selected.local
						? {
								natural_gas: 11,
								bio_mass: 31,
								bio_oil: 5,
								residual_heat_coal: 53,
								bio_gas: 0,
								residual_heat_industry: 0,
								geothermal: 0
						  }
						: {
								natural_gas: 8,
								bio_mass: 25,
								bio_oil: 5,
								residual_heat_coal: 26.5,
								bio_gas: 15,
								residual_heat_industry: 15,
								geothermal: 5.5
						  }
				case 'carbon_emissions_fuel_type':
					return this.$selected.local
						? {
								natural_gas: 9519,
								bio_mass: 26825,
								bio_oil: 4327,
								residual_heat_coal: 45863,
								bio_gas: 0,
								residual_heat_industry: 0,
								geothermal: 0
						  }
						: {
								natural_gas: 10257,
								bio_mass: 32053,
								bio_oil: 6411,
								residual_heat_coal: 33976,
								bio_gas: 19232,
								residual_heat_industry: 19232,
								geothermal: 0
						  }
				case 'location':
					return this.$selected.local
						? { value: 'Amernet' }
						: { value: 'All Heat Networks' }
				default:
					return undefined
			}
		},

		getLatestData() {
			this.$emit('loading')
			setTimeout(() => {
				this.updateData(this.getData())
				this.$emit('loaded')
			}, Math.random() * 100 + 20)
		},

		updateData() {}
	},

	mounted() {
		this.ready = true
		this.getLatestData()
		this.$eventHub.$on('update', this.getLatestData)
	},

	beforeDestroy() {
		this.$eventHub.$off('update', this.getLatestData)
	}
}
</script>
